import { Directive, OnDestroy } from "@angular/core";
import { NgControl } from "@angular/forms";
import { UrlFieldValidators } from "../url-field-validators";

@Directive({
  selector: '[urlPatternValidator]',
  standalone: true
})
export class UrlPatternValidatorDirective implements OnDestroy {
  constructor(private control: NgControl) {
    control && control.control.addValidators(UrlFieldValidators.url);
  }

  ngOnDestroy(): void {
    this.control?.control?.removeValidators(UrlFieldValidators.url);
  }
}
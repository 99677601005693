import { Injectable } from '@angular/core';
import { StoreApiService } from 'src/app/api/services/store-api.service';

import { CompanyStateService } from 'src/app/auth/services/company-state.service';

@Injectable({
  providedIn: 'root'
})
export class TaxExemptionService {

  public loading;
  public taxExemption: any = {};

  constructor(
    private companyStateService: CompanyStateService,
    private storeApiService: StoreApiService
  ) {

  }

  init(submitCallback?) {
    this.loading = false;
    this.taxExemption = {
      callback: submitCallback
    };
  }

  submitTaxExemption() {
    this.taxExemption.error = null;

    this.loading = true;

    return this.storeApiService.uploadTaxExemptionCertificate(this.taxExemption.file)
      .then((blobKey) => {
        return this.storeApiService.addTaxExemption(this.companyStateService.getSelectedCompanyId(), this.taxExemption,
          blobKey);
      })
      .then(() => {
        this.taxExemption.sent = true;

        if (this.taxExemption.callback) {
          this.taxExemption.callback();
        }
      })
      .catch((error) => {
        this.taxExemption.error = error.message || 'Something went wrong. Please try again.';
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
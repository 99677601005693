<div class="attribute-editor-component time-date-container">
  <div class="attribute-editor-row" [shown]="!defaultType">
    <label class="mb-0">Show:</label>
    <mat-radio-group [(ngModel)]="type" name="typeRadio" (change)="save()">
      <div id="timedateRadio">
        <mat-radio-button value="timedate" id="timedate">
          <span class="tw-font-bold" id="timedateLabel">Time & Date</span>
        </mat-radio-button>
      </div>
      <div id="dateRadio">
        <mat-radio-button value="date" id="date">
          <span class="tw-font-bold" id="dateLabel">Date</span>
        </mat-radio-button>
      </div>
      <div id="timeRadio">
        <mat-radio-button value="time" id="time">
          <span class="tw-font-bold" id="timeLabel">Time</span>
        </mat-radio-button>
      </div>
    </mat-radio-group>
  </div>
  <div class="attribute-editor-row" [shown]="type === 'timedate' || type === 'date'">
    <div class="form-group">
      <label class="control-label u_margin-sm-top" for="te-td-date-format">Date format:</label>
      <select id="te-td-date-format"
        class="form-control"
        name="dateFormatSelect"
        [(ngModel)]="dateFormat"
        (change)="save()">
        @for (df of dateFormats; track df) {
          <option [value]="df.format">{{df.date}}</option>
        }
      </select>
    </div>
  </div>
  <div class="attribute-editor-row" [shown]="type === 'timedate' || type === 'time'">
    <label class="u_margin-sm-top mb-0">Time format:</label>
    <mat-radio-group [(ngModel)]="timeFormat" name="timeFormatRadio" (change)="save()">
      <div id="time12HoursRadio">
        <mat-radio-button value="Hours12" id="Hours12">
          <span class="tw-font-bold" id="Hours12Label">12 hour.</span>
        </mat-radio-button>
      </div>
      <div id="time24HoursRadio">
        <mat-radio-button value="Hours24" id="Hours24">
          <span class="tw-font-bold" id="Hours24Label">24 hour.</span>
        </mat-radio-button>
      </div>
    </mat-radio-group>
  </div>
  <div class="attribute-editor-row">
    <label class="u_margin-sm-top mb-0">Show the time and date according to:</label>
    <mat-radio-group [(ngModel)]="timezoneType" name="timezoneOptionRadio" (change)="save()">
      <div id="displayTzRadio">
        <mat-radio-button value="DisplayTz" id="DisplayTz">
          <span class="tw-font-bold" id="DisplayTzLabel">The Display's time zone.</span>
        </mat-radio-button>
      </div>
      <div id="specificTzRadio">
        <mat-radio-button value="SpecificTz" id="SpecificTz">
          <span class="tw-font-bold" id="SpecificTzLabel">A specific time zone.</span>
        </mat-radio-button>
      </div>
    </mat-radio-group>
    <select id="te-td-timezone"
      name="timezoneSelect"
      class="form-control timezone-input-group"
      [shown]="timezoneType === 'SpecificTz'"
      [(ngModel)]="timezone"
      (change)="save()">
      @for (tz of WORLD_TIMEZONES; track tz) {
        <option [value]="tz">{{tz}}</option>
      }
    </select>
  </div>
</div>

<div class="madero-style alert alert-{{type}}" role="alert" aria-live="polite">
  @if (title) {
    <i aria-hidden="true" class="fa icon-left {{alertTypeIconClass[type]}}"></i>
    <strong>{{title}}</strong>
  }
  @if(message) {
    <p class="mt-2">{{message}}</p>
  } @else {
    <div class="mt-2">
      <ng-content></ng-content>
    </div>
  }
</div>
<div rvSpinner="auth-buttons-login" [rvShowSpinner]="loading">
  <div *ngIf="!errors.userEmailNotConfirmedError && !errors.invalidConfirmationTokenError">
    <div class="panel-body">

      <h4>Welcome Back!</h4>
      <p>Please enter your details.</p>

      <!-- CUSTOM LOGIN -->
      <div>
        <!-- Error Message -->
        <div class="panel-body bg-danger u_margin-sm-top" [shown]="errors.message">
          <p><strong>{{errors.messageTitle}}</strong></p>
          <p class="u_remove-bottom" [innerHTML]="errors.message"></p>
        </div>

        <!-- Generic Error -->
        <div class="panel-body bg-danger u_margin-sm-top" [shown]="errors.genericError">
          <p class="u_remove-bottom">
            <i aria-hidden="true" class="fa fa-warning icon-left"></i>
            <span id="incorrect-credentials-error">Your email address/password combination is incorrect. <br/>If you are having problems signing in, please check this <a href="https://help.risevision.com/hc/en-us/articles/115001402743-I-am-having-trouble-logging-in-what-can-I-do-" target="_blank">Help Center article</a>.</span>
          </p>
        </div>

        <!-- Password Reset Notice -->
        <div class="panel-body bg-info u_margin-sm-top" [shown]="messages.passwordReset">
          <p class="u_remove-bottom">
            <i aria-hidden="true" class="fa fa-warning icon-left"></i>
            <span>Password successfully updated. <br/> Please sign in to proceed.</span>
          </p>
        </div>

        <!-- Use Google Account Error -->
        <div class="panel-body bg-danger u_margin-sm-top" [shown]="messages.isGoogleAccount">
          <p class="u_remove-bottom">
            <i aria-hidden="true" class="fa fa-warning icon-left"></i>
            <span>This account is authenticated by Google. <br/> Please click the 'Sign in with Google' button below.</span>
          </p>
        </div>

        <!-- SSO Required Error -->
        <div class="panel-body bg-danger u_margin-sm-top" [shown]="messages.ssoRequired">
          <p class="u_remove-bottom">
            <i aria-hidden="true" class="fa fa-warning icon-left"></i>
            <span>Single Sign-on is required for this account. <br/> Please click the 'Continue with Single Sign-on' link below.</span>
          </p>
        </div>

        <!-- Account Locked Out Error -->
        <div class="panel-body bg-danger u_margin-sm-top" [shown]="errors.userAccountLockoutError">
          <p class="u_remove-bottom">
            <i aria-hidden="true" class="fa fa-warning icon-left"></i>
            <span id="user-account-lockout-error">
              Hmm, you've had too many failed login attempts.
              As a security precaution, we've blocked access to your account.
              You can try again in 24 hours.
              <br/>
              Feel like your account shouldn't be blocked?
              Please <a target="_blank" href="mailto:support@risevision.com">reach out to our Support team</a>.
            </span>
          </p>
        </div>

        <!-- Use Invitation Link Error -->
        <div class="panel-body bg-danger u_margin-sm-top" [shown]="errors.termsNotAcceptedError">
          <p class="u_remove-bottom">
            <i aria-hidden="true" class="fa fa-warning icon-left"></i>
            <span id="invalid-request-url">
              This email address has been invited to join a company. Please use the link in the invitation email to activate your account.
            </span>
          </p>
        </div>

        <auth-form
          [credentials]="credentials"
          [isSignUp]="false"
          [ssoChecked]="ssoChecked"
          [ssoEnabled]="ssoEnabled"
          [(usePassword)]="usePassword"
          (checkSso)="checkSso()"
          (googleLogin)="googleLogin()"
          (submitForm)="ssoLogin($event)"
          (loading)="loading = $event">
        </auth-form>

      </div>

    </div>
  </div><!--panel-->

  <div class="panel-body">
    <!-- Use Confirmation Required Error -->
    <div [shown]="errors.userEmailNotConfirmedError">
      <confirm-email [username]="credentials.username"></confirm-email>
    </div>

    <!-- Invalid Confirmation Token Error -->
    <div [shown]="errors.invalidConfirmationTokenError">
      <confirm-email [username]="credentials.username" errorMessage="Invalid confirmation token."></confirm-email>
    </div>
  </div>

  <div class="align-center u_margin-md-top" *ngIf="!errors.userEmailNotConfirmedError && !errors.invalidConfirmationTokenError">
    <p><a class="madero-link" id="reset-password-link" uiSref="common.auth.requestpasswordreset">Forgot your password?</a></p>
    @if (!this.rvshareAppDetectionService.isScreenShareApp()) {
      <p>Don't have an account? <a class="madero-link" id="sign-up-link" uiSref="common.auth.createaccount">Sign up</a>.</p>
    } 
    @else {
        <div class="flex flex-row gap-2 cursor-pointer center" (click)="goToJoin(true)">
          <mat-icon svgIcon="chevron-left" />
          <span> <a class="madero-link"> Go back </a> </span>
        </div>
    }
    </div>
</div>

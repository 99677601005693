<div class="form-group mt-4">
  <div class="flex-row schedule-type">
    <label class="control-label schedule-type-label pull-left pt-2 u_margin-right aligner-item--top">Override:</label>
    <div class="row-wrap flex-grow">
      <div class="schedule-type-value" [shown]="readonly">
        <span>{{ description }}</span>
      </div>
      <div class="schedule-type-value ml-2" [shown]="!readonly">
        <mat-radio-group name="schedule-type-options" class="tw-flex tw-flex-row tw-w-full" [(ngModel)]="scheduleType" (change)="detectChanges()">
          <mat-radio-button
            class="mr-4" 
              id="schedule-is-default" 
              name="scheduleIsDefault" 
              value="default">
              <span class="control-label-secondary">None</span>
            </mat-radio-button>
            <mat-radio-button 
              class="mr-4"
              id="schedule-override-replace" 
              name="scheduleOverrideTypeReplace" 
              value="override_replace"
              [disabled]="!isOverrideAvailable">
              <span class="control-label-secondary">Replace</span>
              </mat-radio-button>
              <mat-radio-button 
                id="schedule-override-include" 
                name="scheduleOverrideTypeInclude" 
                value="override_insert"
                [disabled]="!isOverrideAvailable">
              <span class="control-label-secondary">Include</span>
          </mat-radio-button>
        </mat-radio-group>
        <div class="text-muted" [shown]="isOverrideAvailable">
          <span [shown]="scheduleType === 'default'">
            This schedule is the default display content.
          </span>
          <span [shown]="scheduleType === 'override_insert'">
            This schedule will be included with the default display content.
          </span>
          <span [shown]="scheduleType === 'override_replace'">
            This schedule will replace the default display content.
          </span>
        </div>
        <div class="flex-row w-100 mt-2" [shown]="false && scheduleType === 'override_replace'" *requireRole="'sa'">
          <mat-checkbox checkboxId="schedule-play-once" [(ngModel)]="playOnce" ngDefaultControl (ngModelChange)="detectChanges()" [disabled]="!isOverrideAvailable"></mat-checkbox>
          <label for="schedule-play-once" class="rise-admin-only control-label-secondary">Play once</label>
        </div>
      </div>
      <div class="schedule-type-value mt-2" [shown]="!isOverrideAvailable">
        <span [shown]="isOverrideAvailable === null">
          Schedule Override feature requires an active subscription.
          <a class="madero-link" href="#" uiSref="apps.purchase.home">Subscribe Now</a>
        </span>
        <span [shown]="isOverrideAvailable !== null">
          Schedule Override feature requires a plan upgrade.
          <a class="madero-link" href="#" (click)="showUpgradePlanModal()">Upgrade Plan</a>
        </span>
      </div>
    </div>
  </div>
</div>

import { Directive, Input, OnDestroy } from "@angular/core";
import { NgControl } from "@angular/forms";
import { StorageUtilsService } from "src/app/storage/services/storage-utils.service";
import { UrlFieldValidators } from "../url-field-validators";

 @Directive({
   selector: '[urlFileTypeValidator]',
   standalone: true
 })
export class UrlFileTypeValidatorDirective implements OnDestroy {
  private currentValidator: any = null;

  @Input() set acceptedFileTypes(type: keyof typeof StorageUtilsService.SELECTOR_FILTERS) {
    if (this.currentValidator) {
      this.control?.control?.removeValidators(this.currentValidator);
    }
    this.currentValidator = UrlFieldValidators.file(type);
    this.control?.control?.addValidators(this.currentValidator);
    this.control?.control?.updateValueAndValidity();
  }

  constructor(private control: NgControl) {}

  ngOnDestroy() {
    if (this.currentValidator) {
      this.control?.control?.removeValidators(this.currentValidator);
      this.control?.control?.updateValueAndValidity();
    }
  }
}
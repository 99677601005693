import { Component, ElementRef } from '@angular/core';

import { cloneDeep } from 'lodash';

import { FileDownloader } from 'src/app/ajs-upgraded-providers';

import { CurrentPlanService } from 'src/app/components/plans/current-plan.service';
import { ComponentsService } from '../../services/components.service';
import { AttributeDataService } from '../../services/attribute-data.service';
import { TemplateEditorService } from '../../services/template-editor.service';
import { TemplateEditorUtilsService } from '../../services/template-editor-utils.service';
import { BaseImageService } from '../services/base-image.service';
import { LogoImageService } from '../services/logo-image.service';
import { StorageManagerService } from 'src/app/storage/services/storage-manager.service';
import { FileMetadataUtilsService } from '../services/file-metadata-utils.service';
import { FileExistenceCheckService } from '../services/file-existence-check.service';
import { StockLibraryService } from '../services/stock-library.service';
import { FeaturesService } from 'src/app/components/plans/features.service';
import { StorageUtilsService } from 'src/app/storage/services/storage-utils.service';

@Component({
  selector: 'template-component-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageComponent {
  private imageService: any = this.baseImageService;

  public componentId: string;

  uploadManager: any;
  isUploading: boolean;
  fileUploadList: File[] = [];
  boundAddFile = this.addFile.bind(this);
  values = {
    duration: 0,
    transition: null
  };
  isDefaultImageList: boolean;
  selectedImages;
  helpText: string;

  constructor(private elementRef: ElementRef,
    private componentsService: ComponentsService,
    private attributeDataService: AttributeDataService,
    public templateEditorService: TemplateEditorService,
    private templateEditorUtils: TemplateEditorUtilsService,
    private storageUtilsService: StorageUtilsService,
    private baseImageService: BaseImageService,
    private logoImageService: LogoImageService,
    private storageManagerService: StorageManagerService,
    private fileMetadataUtilsService: FileMetadataUtilsService,
    private fileExistenceCheckService: FileExistenceCheckService,
    private fileDownloader: FileDownloader,
    public stockLibraryService: StockLibraryService,
    public currentPlanService: CurrentPlanService,
    public featuresService: FeaturesService) {

    this.componentsService.registerDirective({
      type: 'rise-image',
      element: this.elementRef.nativeElement,
      show: () => {
        this.imageService = this.baseImageService;
        this.imageService.componentId = this.componentsService.selected.id;

        this._init();
      },
      onPresentationOpen: () => {
        this.baseImageService.componentId = null;
      },
      getName: (componentId) => {
        const files = this._getFilesFor(componentId);

        if (files && files.length > 0) {
          return this.storageUtilsService.fileNameOf(files[0]);
        } else {
          return null;
        }
      }
    });

    this.componentsService.registerDirective({
      type: 'rise-image-logo',
      element: this.elementRef.nativeElement,
      show: () => {
        this.imageService = this.logoImageService;
        this.imageService.componentId = null;

        this._init();
      }
    });

    this.uploadManager = {
      onUploadStatus: (isUploading) => {
        this.isUploading = isUploading;
      },
      addFile: (file) => {
        this._addFilesToMetadata([file]);
      },
      isSingleFileSelector: this.isEditingLogo.bind(this)
    };

    this._reset();
  }

  isEditingLogo() {
    return this.imageService === this.logoImageService;
  }

  _reset() {
    this._setSelectedImages([]);
    this.isUploading = false;
  }

  _addFilesToMetadata(files, alwaysAppend?) {
    const selectedFiles = this.isDefaultImageList ? [] : this.selectedImages;
    let metadata = this.fileMetadataUtilsService.metadataWithFile(selectedFiles,
      LogoImageService.DEFAULT_IMAGE_THUMBNAIL, files, alwaysAppend);

    metadata = this.imageService.updateMetadata(metadata);

    this._setSelectedImages(metadata);

  }

  _loadSelectedImages() {
    const selectedImages = this.imageService.getImagesAsMetadata();

    if (selectedImages) {
      this._setSelectedImages(selectedImages);
    }

    this.templateEditorService.loadingPresentation = true;

    this._checkFileExistenceFor(this.imageService.componentId)
      .finally(() => {
        this.templateEditorService.loadingPresentation = false;
      });
  }

  _getFilesFor(componentId) {
    const metadata = this.attributeDataService.getAttributeData(componentId, 'metadata');

    if (!metadata) {
      const files = this.attributeDataService.getAvailableAttributeData(componentId, 'files');

      return this.fileMetadataUtilsService.filesAttributeToArray(files);
    }

    return this.fileMetadataUtilsService.extractFileNamesFrom(metadata);
  }

  updateFileMetadata(componentId, newMetadata) {
    const currentMetadata = this.attributeDataService.getAttributeData(componentId, 'metadata');
    const updatedMetadata = this.fileMetadataUtilsService.getUpdatedFileMetadata(currentMetadata, newMetadata);

    if (updatedMetadata) {
      this._setMetadata(componentId, updatedMetadata);
    }
  }

  _setMetadata(componentId, metadata) {
    const selectedImages = cloneDeep(metadata);
    const filesAttribute = this.fileMetadataUtilsService.filesAttributeFor(selectedImages);

    if (componentId === this.imageService.componentId) {
      this._setSelectedImages(selectedImages);
    }

    this.attributeDataService.setAttributeData(componentId, 'metadata', selectedImages);
    this.attributeDataService.setAttributeData(componentId, 'files', filesAttribute);
  }

  _setSelectedImages(selectedImages) {
    const filesAttribute = this.fileMetadataUtilsService.filesAttributeFor(selectedImages);

    // Show logo in the image list if component is set to use logo and a logo is available
    const logoAsMetadata = this.logoImageService.getImagesAsMetadata();
    if (!this.isEditingLogo() && this.imageService.isSetAsLogo() && logoAsMetadata.length > 0) {
      this.selectedImages = logoAsMetadata;
    }

    this.selectedImages = selectedImages;
    this.isDefaultImageList = filesAttribute === this.imageService.getBlueprintData('files');
  }

  saveDuration() {
    this.imageService.setDuration(this.values.duration);
  }

  saveTransition() {
    this.imageService.setTransition(this.values.transition);
  }

  _init() {
    this._reset();

    this.storageManagerService.fileType = 'image';
    this.storageManagerService.isSingleFileSelector = this.isEditingLogo.bind(this);
    this.storageManagerService.onSelectHandler = (newSelectedItems) => {
      this._addFilesToMetadata(newSelectedItems, true);
    };

    this._loadSelectedImages();

    const duration = this.imageService.getAvailableAttributeData('duration');

    // default to value 10 if duration not defined
    this.values.duration = this.templateEditorUtils.intValueFor(duration, 10);

    this.values.transition = this.imageService.getAvailableAttributeData('transition');
    this.helpText = this.imageService.getHelpText();
  }

  _checkFileExistenceFor(componentId): Promise<void> {
    if (!componentId) {
      return Promise.resolve();
    }

    const files = this._getFilesFor(componentId);

    return this.fileExistenceCheckService.requestMetadataFor(files, LogoImageService.DEFAULT_IMAGE_THUMBNAIL)
      .then((metadata) => {
        console.info('received metadata', metadata);

        this.updateFileMetadata(componentId, metadata);
      })
      .catch((error) => {
        console.error('Could not check file existence for: ' + componentId, error);
      });
  }

  onDesignPublished(options) {
    console.info('Canva result:', options);
    let filepath = StorageManagerService.CANVA_FOLDER;
    filepath += options.designTitle ? options.designTitle + '_' : '';
    filepath += options.designId + '.png';
    this.fileDownloader(options.exportUrl, filepath)
      .then((file) => {
        this.fileUploadList = [file];
      })
      .catch((err) => {
        console.error('Could not import Canva design.', err);
      });
  }

  selectFromStorage() {
    this.componentsService.editComponent({
      type: 'rise-storage-selector'
    });
  }

  activateImageUploader() {
    const uploader = document.getElementById('image-list-uploader') as HTMLInputElement;
    if (uploader) {
      uploader.click();
    }
  }

  removeImageFromList(image) {
    this.imageService.removeImage(image, this.selectedImages).then((updatedMetadata) => {
      setTimeout(() => { //force change detection angular=>angularjs (can be removed after component migration)
        this._setSelectedImages(updatedMetadata);
      });
    });
  }

  sortItem(evt) {
    const oldIndex = evt.data.oldIndex;
    const newIndex = evt.data.newIndex;

    this.selectedImages.splice(newIndex, 0, this.selectedImages.splice(oldIndex, 1)[0]);
  }

  addFile(file: File) {
    this._addFilesToMetadata([file]);
  }

}

import { Injectable } from '@angular/core';

import * as angular from 'angular';

import * as _ from 'lodash';

import { InsecureUrl } from 'src/app/ajs-upgraded-providers';

import { Subject } from 'rxjs';
import { TrackerService } from 'src/app/components/logging/tracker.service';
import { ModalService } from 'src/app/components/modals/modal.service';
import { PresentationsService } from 'src/app/editor/services/presentations.service';
import { TemplatesService } from 'src/app/editor/services/templates.service';
import { BlueprintService } from 'src/app/template-editor/services/blueprint.service';
import { TemplateEditorService } from 'src/app/template-editor/services/template-editor.service';
import { ScheduleService } from './schedule.service';

import { PlaylistItemComponent } from '../components/playlist-item/playlist-item.component';

@Injectable({
  providedIn: 'root'
})
export class PlaylistService {
  static TYPE_URL = 'url';
  static TYPE_PRESENTATION = 'presentation';
  static TRANSITION_TYPES = [
    ['normal', 'No transition'],
    ['fadeIn', 'Fade in'],
    ['slideFromLeft', 'Slide from left'],
    ['slideFromRight', 'Slide from right'],
    ['slideFromTop', 'Slide from top'],
    ['slideFromBottom', 'Slide from bottom'],
    ['stripesHorizontal', 'Stripes horizontal'],
    ['stripesVertical', 'Stripes vertical'],
    ['zoomIn', 'Zoom in']
  ];

  private DEFAULT_DURATION = 10;

  playlistSubject = new Subject();
  appleTVCompatiblePresentations = null;

  constructor(private modalService: ModalService,
    private scheduleService: ScheduleService,
    private trackerService: TrackerService,
    private templatesService: TemplatesService,
    private presentationsService: PresentationsService,
    private blueprintService: BlueprintService,
    private insecureUrl: InsecureUrl) { }

  newPresentationItem (presentation) {
    this.trackerService.scheduleEvent('Add Presentation to Schedule',
      this.scheduleService.schedule.id, this.scheduleService.schedule.name, {
        presentationId: presentation.id,
        presentationName: presentation.name
      }
    );

    // Cache presentation to avoid API call for the name
    this.presentationsService.setPresentation(presentation);

    return {
      duration: this.DEFAULT_DURATION,
      type: PlaylistService.TYPE_PRESENTATION,
      objectReference: presentation.id,
      name: presentation.name,
      presentationType: presentation.presentationType
    };
  }

  initPlayUntilDone (item, presentation, isNew) {
    if (presentation.presentationType === TemplateEditorService.HTML_PRESENTATION_TYPE) {
      return this.blueprintService.isPlayUntilDone(presentation.productCode)
        .then((playUntilDone) => {
          if (playUntilDone && isNew) {
            //When user schedules a PUD template, then set schedule item to PUD by default.
            item.playUntilDone = true;
          }
          if (!playUntilDone) {
            item.playUntilDone = false;
          }

          return playUntilDone;
        })
        .catch((e) => {
          console.error('Failed to check HTML Template Play Until Done', e);

          item.playUntilDone = false;

          return false;
        });
    } else {
      return Promise.resolve(true);
    }
  }

  addPresentationItem (presentation) {
    var playlistItem = this.newPresentationItem(presentation);

    return this.initPlayUntilDone(playlistItem, presentation, true)
      .then(() => {
        this.updatePlaylistItem(playlistItem);
      });
  }

  addPresentationItems (presentations) {
    for (var i in presentations) {
      this.addPresentationItem(presentations[i]);
    }
  }

  getNewUrlItem () {
    this.trackerService.scheduleEvent('Add URL Item to Schedule',
      this.scheduleService.schedule.id, this.scheduleService.schedule.name
    );

    return {
      duration: this.DEFAULT_DURATION,
      type: PlaylistService.TYPE_URL,
      name: 'URL Item'
    };
  }

  getPlaylist () {
    return this.scheduleService.schedule.content ? this.scheduleService.schedule.content :
      this.scheduleService.schedule.content = [];
  }

  _getItemIndex (playlistItem) {
    return this.getPlaylist() ?
      this.getPlaylist().indexOf(playlistItem) : -1;
  }

  isNew (playlistItem) {
    return this._getItemIndex(playlistItem) === -1;
  }

  updatePlaylistItem (playlistItem) {
    if (this._getItemIndex(playlistItem) === -1) {
      this.getPlaylist().push(playlistItem);
    }

    this.playlistSubject.next(this.getPlaylist());
  }

  removePlaylistItem (playlistItem) {
    var index = this._getItemIndex(playlistItem);
    if (index !== -1) {
      this.getPlaylist().splice(index, 1);

      this.playlistSubject.next(this.getPlaylist());
    }
  }

  duplicatePlaylistItem (playlistItem) {
    var index = this._getItemIndex(playlistItem);
    if (index !== -1) {
      var newPlaylistItem = angular.copy(playlistItem);
      newPlaylistItem.name = 'Copy of ' + newPlaylistItem.name;

      this.getPlaylist().splice(index + 1, 0, newPlaylistItem);

      this.playlistSubject.next(this.getPlaylist());
    }
  }

  moveItem (currIndex, newIndex) {
    var playlist = this.getPlaylist();

    playlist.splice(newIndex, 0, playlist.splice(currIndex, 1)[0]);

    this.playlistSubject.next(this.getPlaylist());
  }

  getItemTransition (playlistItem) {
    var transition = _.find(PlaylistService.TRANSITION_TYPES, (t) => {
      return playlistItem.transitionType === t[0];
    });

    return transition ? transition[1] : PlaylistService.TRANSITION_TYPES[0][1];
  }

  hasInsecureUrl (playlistItem) {
    return !!(playlistItem && playlistItem.type === 'url' && this.insecureUrl(playlistItem.objectReference));
  }

  checkAppleTVCompatibleStatus() {
    let items = this.scheduleService.schedule?.content || [];
    let presentationsItems = items
      .filter(this.isItemTemplatePresentation)
      .map(presentation => presentation.objectReference)
      .map(presentationId => this.presentationsService.getPresentationCached(presentationId));
    let incompatibleItemsExist = !items.every(this.isItemTemplatePresentation);

    if (items.length === 0) {
      return Promise.resolve(true);
    }

    if (incompatibleItemsExist && presentationsItems.length === 0) {
      return Promise.resolve(false);
    }

    this.appleTVCompatiblePresentations = null;

    return Promise.all(presentationsItems).then(presentations => {
      let templateIds = presentations.map(presentation => presentation.productCode);

      return this.templatesService.getCachedTemplatesByIds(templateIds).then(templates => {
        this.appleTVCompatiblePresentations = presentations.filter(presentation => {
          let template = templates.items.find(template => template.productCode === presentation.productCode);
          return template && template.supportsAppleTV;
        });

        return !incompatibleItemsExist && templates.items.every(template => template.supportsAppleTV);
      });
    }).finally(() => {
      this.appleTVCompatiblePresentations = this.appleTVCompatiblePresentations || [];
    });
  }

  isItemTemplatePresentation(item) {
    return item && item.type === PlaylistService.TYPE_PRESENTATION && item.presentationType === 'HTML Template';
  }

  isItemAppleTVCompatible(item) {
    return !this.scheduleService.hasAppleTvDisplays() || !this.appleTVCompatiblePresentations ||
      this.isItemTemplatePresentation(item) && this.appleTVCompatiblePresentations.some(p => p.id === item.objectReference);
  }
  editPlaylistItem(playlistItem) {
    this.modalService.showMediumModal(PlaylistItemComponent, {
      initialState: {
        playlistItem
      }
    });
  }

}
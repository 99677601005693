import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import * as _ from 'lodash';

import { HttpClient } from '@angular/common/http';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';
import { ModalComponent } from 'src/app/components/modals/modal.component';
import { UrlFieldValidators } from 'src/app/components/url-field/url-field-validators';
import { PresentationsService } from 'src/app/editor/services/presentations.service';
import { PlaylistService } from '../../services/playlist.service';

@Component({
  selector: 'playlist-item',
  templateUrl: './playlist-item.component.html',
  styleUrls: ['./playlist-item.component.scss']
})
export class PlaylistItemComponent extends ModalComponent implements OnInit {

  transitionTypes = PlaylistService.TRANSITION_TYPES;
  companyId = this.companyStateService.getSelectedCompanyId();

  playlistItem: any = null;
  _playlistItem;

  isNew;
  playUntilDoneSupported;
  loadingTemplate;

  playlistItemForm: FormGroup<PlaylistItemForm>;

  constructor(
    private companyStateService: CompanyStateService,
    private playlistService: PlaylistService,
    private presentationsService: PresentationsService,
    private httpClient: HttpClient,
    private formBuilder: FormBuilder,
    public modalRef: BsModalRef
  ) {
    super(modalRef);
    this.setupForm();
  }

  ngOnInit(): void {
    if (this.playlistItem.type === 'url') {
      this.addUrlControlField();
    }
    this._playlistItem = _.cloneDeep(this.playlistItem);
    this.isNew = this.playlistService.isNew(this.playlistItem);

    this.configurePlayUntilDone();
  }

  selectPresentation () {
    this.presentationsService.openPresentationSelector()
      .then((presentationDetails) => {
        this._playlistItem.objectReference = presentationDetails[0];
        this._playlistItem.presentationType = presentationDetails[2];
        this.configurePlayUntilDone();
      });
  }

  configurePlayUntilDone() {
    if (this._playlistItem.type === 'url') {
      return;
    }

    this.playUntilDoneSupported = true;
    this.loadingTemplate = true;

    this.presentationsService.getPresentationCached(this._playlistItem.objectReference)
      .then((presentation) => {
        console.log(this._playlistItem);
        return this.playlistService.initPlayUntilDone(this._playlistItem, presentation, this.isNew);
      })
      .then((playUntilDone) => {
        this.playUntilDoneSupported = playUntilDone;
      })
      .finally(() => {
        this.loadingTemplate = false;
      });
  }

  private addUrlControlField() {
    this.playlistItemForm.addControl(
      'objectReference',
      this.formBuilder.control(
        '', Validators.compose([
        Validators.required,
        UrlFieldValidators.url,
        UrlFieldValidators.http,
        UrlFieldValidators.noPreviewUrl,
      ]), UrlFieldValidators.responseHeader(this.httpClient))
    );
    this.playlistItemForm.updateValueAndValidity();
  }

  private setupForm() {
    this.playlistItemForm = this.formBuilder.group<PlaylistItemForm>({
      itemName: this.formBuilder.nonNullable.control('', Validators.required),
      playUntilDone: this.formBuilder.control(false),
      duration: this.formBuilder.control(0, Validators.required),
      transitionType: this.formBuilder.control(null),
    });
  }

  saveDisabled() {
    return (
      (
        this.f['duration']
        && this.f['duration'].invalid
      ) ||
      (
        !!this.f['objectReference']
        && this.f['objectReference'].invalid
        && (
          this.f['objectReference'].dirty || this.isNew
        )
      )
    );
  }

  save () {
    if (this.saveDisabled()) {
      console.error('form not valid');
      return;
    }
    Object.assign(this.playlistItem, {...this._playlistItem, ...this.playlistItemForm.value});
    this.playlistService.updatePlaylistItem(this.playlistItem);
    this.resolve();
  }

  dismiss () {
    this.reject();
  }

  protected get f() {
    return this.playlistItemForm.controls;
  }
}

type PlaylistItemForm = {
  itemName: FormControl<string>;
  objectReference?: FormControl<string>;
  playUntilDone: FormControl<boolean>;
  duration: FormControl<number>;
  transitionType: FormControl<string>;
}

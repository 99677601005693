<div class="attribute-editor-component">
  <div class="attribute-editor-row counter-container">
    <label [shown]="counterType === 'down'" class="u_margin-sm-top">Countdown to:</label>
    <label [shown]="counterType === 'up'" class="u_margin-sm-top">Count up from:</label>
    
    <mat-radio-group [(ngModel)]="targetUnit" name="targetUnitSelector" (change)="save()">
      <div id="dateRadio">
        <mat-radio-button 
          value="targetDate" 
          id="specificDate" 
          aria-required="true" 
          required>
          <span class="tw-font-bold" id="specificDateLabel">A specific date and time:</span>
        </mat-radio-button>
        
        <mat-form-field
          class="target-date date-picker"
          id="targetDate"
          appearance="outline"
          [shown]="targetUnit === 'targetDate'">
          <input matInput
            [matDatepicker]="picker"
            [(ngModel)]="targetDate"
            ngDefaultControl
            placeholder="MM/DD/YYYY"
            (ngModelChange)="save()" />
          <button mat-icon-button matIconSuffix type="button" (click)="picker.open()"><i class="fa fa-calendar"></i></button>
          <mat-datepicker
            [startAt]="matDatepickerConfig.startAt"
            [calendarHeaderComponent]="calendarMonthYearHeader"
            [startView]="matDatepickerConfig.startView"
            #picker />
        </mat-form-field>
        <popup-time-picker 
          id="targetDateTime" 
          [(ngModel)]="targetDateTime" 
          ngDefaultControl 
          (ngModelChange)="save()" 
          [shown]="targetUnit === 'targetDate'">
        </popup-time-picker>
      </div>

      <div id="timeRadio">
        <mat-radio-button 
          value="targetTime" 
          id="specificTime" 
          aria-required="true" 
          required>
          <span class="tw-font-bold" id="specificTimeLabel">A specific time, every day:</span>
        </mat-radio-button>
        <popup-time-picker 
          id="targetTime" 
          [(ngModel)]="targetTime" 
          ngDefaultControl 
          (ngModelChange)="save()" 
          [shown]="targetUnit === 'targetTime'">
        </popup-time-picker>
      </div>
    </mat-radio-group>

    <div class="form-group mt-4" [shown]="counterType === 'down' && !nonCompletion">
      <label class="control-label" for="completionMessage">Enter countdown completion message:</label>
      <input type="text" 
        id="completionMessage" 
        class="form-control u_ellipsis" 
        [(ngModel)]="completionMessage" 
        (change)="save()" 
        placeholder="Eg. &quot;It's time for...&quot;">
    </div>
  </div>
</div>

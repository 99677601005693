<main class="u_overflow-hidden h-100">
  @if (!isScreenSharingSupported()) {
    <div class="top-menu">
      <div>
        <img src="https://s3.amazonaws.com/Rise-Images/Website/rise-logo.svg" width="100">
        <a href="#">Need help?</a>
      </div>
    </div>
    <div class="join-panel aligner p-4">
      <div class="container p-5 flex-col aligner">
        <div class="flex-grow flex-col flex-wrap aligner mr-4 ml-4">
          <img class="w-25 mb-3" src="/images/screen-sharing/download-app.png" alt="Downlad App">
          <h4 class="mt-4 mb-4">Download App</h4>
          <p>To share your screen from your phone, you need to download the Screen Share by Rise Vision app.</p>
          <a href="https://play.google.com/store/apps/details?id=com.risevision.rvshareandroid">
            <img class="w-100 p-1" src="/images/screen-sharing/google-play.png" alt="Googe Play">
          </a>
          <a href="https://apps.apple.com/us/app/rise-vision-share/id6670492603">
            <img class="w-100 p-1" src="/images/screen-sharing/app-store.png" alt="iOS Beta Testing">
          </a>
        </div>
      </div>
    </div>
  }
  @else if (!joined) {
    <div class="flex-row">
      <div class="join-panel flex-col p-4 u_scroll-x" (keyup.enter)="join()">
        <div class="flex-row space-between py-2 pl-3">
          <img src="https://s3.amazonaws.com/Rise-Images/Website/rise-logo.svg" width="100">
          <a class="madero-link p-3" target="_blank" href="https://help.risevision.com/hc/en-us/articles/29009023259156-How-do-I-Set-Up-Screen-Sharing-on-a-display-in-Rise-Vision-Coming-Soon">Need help?</a>
        </div>
        <div class="aligner flex-grow">
          <div class="join-content">
            @if (screenSharingService.channelError) {
              <div class="madero-style alert alert-danger">
                <div class="flex flex-row align-start">
                  <mat-icon aria-hidden="true" fontIcon="fa-times-circle" class="icon-left"></mat-icon>
                  <p class="mb-0">
                    {{ screenSharingService.channelError}}
                  </p>
                </div>
              </div>
            }
            <h3 class="font-weight-bold">Share your screen</h3>
            @if (requirePin) {
              <p>Enter your secret PIN code to start sharing your screen.</p>
              <div class="form-group required pt-3">
                <label>PIN</label>
                <div class="join-code-input">
                  <code-input
                    #pinInput
                    [codeLength]="pinLength"
                    [initialFocusField]="0"
                    (codeChanged)="pinChanged($event)"
                    (codeCompleted)="pinCompleted($event)"
                  />
                </div>
                <p [shown]="screenSharingService.joinCodeError" class="help-block validation-error text-danger">{{screenSharingService.joinCodeError}}</p>
                <p [shown]="pinMissing" class="help-block validation-error text-danger">Please enter the complete PIN Code</p>
              </div>
            } @else {
              <p>Enter your name and the session code on the display to join a room and share your screen.</p>
              <div class="form-group required pt-3">
                <label>Session Code</label>
                <div class="join-code-input">
                  <code-input
                    #codeInput
                    [codeLength]="codeLength"
                    [isCharsCode]="true"
                    inputType="text"
                    inputMode="text"
                    [initialFocusField]="0"
                    autocapitalize="characters"
                    (codeChanged)="codeChanged($event)"
                    (codeCompleted)="codeCompleted($event)"
                  />
                </div>
                <p [shown]="screenSharingService.joinCodeError" class="help-block validation-error text-danger">{{screenSharingService.joinCodeError}}</p>
                <p [shown]="codeBlank" class="help-block validation-error text-danger">Please enter the complete Session Code</p>
              </div>
              <div class="form-group required py-3">
                <label>Name</label>
                <input #nameInput class="form-control light-border" />
                <p [shown]="nameBlank" class="help-block validation-error text-danger">This field cannot be left blank</p>
              </div>
            }
            <button mat-flat-button color="primary" class="btn-block mt-5" [disabled]="screenSharingService.connecting" (click)="join()">
              @if(screenSharingService.connecting) {
                <mat-spinner diameter="15" class="white"></mat-spinner>
              } @else {
                Start Sharing
              }
            </button>
            @if (userStateService.isModerator() || rvshareAppDetectionService.isScreenShareApp()) {
              <div class="align-center mt-5 py-3">
                <a class="p-3 madero-link" uiSref="apps.screen-sharing.moderator-join" [uiOptions]="{ reload: true }">Or join as moderator</a>
              </div>
            }
          </div>
        </div>
      </div>
      <div class="background-panel"></div>
    </div>
  }
  @else {
    <div class="container p-5 h-100vh flex-col aligner joined-content">
      <screen-sharing-controls
        style="display: contents"
        [isStopped]="stopped"
        [isPaused]="paused"
        [moderatorName]="moderatorName"
        (startStream)="start()"
        (pauseStream)="pause()"
        (resumeStream)="resume()"
        (stopStream)="stop()"
        (endSession)="end()">
      </screen-sharing-controls>
      <div>
        <button mat-button class="px-4" (click)="end()">
          <mat-icon svgIcon="exit" aria-hidden="true"></mat-icon>
          Exit Session
        </button>
      </div>
    </div>
  }
</main>

import { AfterViewInit, Component, DestroyRef, ElementRef, forwardRef, inject, Input, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { close as closeColorPicker, coloris, init as initColorPicker } from '@melloware/coloris';
import { fromEvent, tap } from 'rxjs';

/* eslint-disable @angular-eslint/directive-class-suffix */
@Component({
  selector: 'color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ColorPickerComponent),
      multi: true
    }
  ]
})
export class ColorPickerComponent
  implements ControlValueAccessor, AfterViewInit, OnInit {
  @ViewChild('colorInput') colorInput: ElementRef | undefined;
  protected color = '';

  @Input({ required: true }) pickerId!: string;
  @Input() placeholderText!: string;

  protected disabled = false;
  protected onTouch!: () => void;
  protected onChanges!: (value: string) => void;

  private destroyRef = inject(DestroyRef);

  ngOnInit() {
    if (!this.pickerId) {
      throw new Error('ColorPickerComponent: pickerId is required');
    }
  }

  ngAfterViewInit(): void {
    initColorPicker();
    this.startColorPicker();
  }

  protected startColorPicker(): void {
    coloris({
      el: `#${this.pickerId}`,
      themeMode: 'auto',
      format: 'rgb',
      formatToggle: false,
      alpha: true,
      focusInput: true,
    });
    const inputColorValue = document.getElementById('clr-picker');
    if (inputColorValue) {
      fromEvent<KeyboardEvent>(inputColorValue, 'keydown')
        .pipe(
          takeUntilDestroyed(this.destroyRef),
          tap((event) => event.key === 'Enter' && closeColorPicker())
        ).subscribe();
    }
    if (this.colorInput) {
      this.colorInput.nativeElement.value = this.color ?? '';
      this.colorInput.nativeElement.dispatchEvent(new Event('input', {bubbles: true}));
    }
  }

  protected onColorChange(value: string): void {
    this.onTouch();
    this.onChanges(value);
  }

  writeValue(obj: any): void {
    this.color = obj;
    setTimeout(() => {
      this.startColorPicker();
    });
  }

  registerOnChange(fn: any): void {
    this.onChanges = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}

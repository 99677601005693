<!-- Step 5 -->
<div class="bg-lighter-gray p-4">
  <h4 class="u_margin-md-bottom mt-0">Subscription Details</h4>
  <div id="errorBox" class="madero-style alert alert-danger u_margin-md-top" role="alert" [shown]="purchase.estimate.estimateError">
    <strong>Tax Estimate Error</strong> {{purchase.estimate.estimateError}}
    <a aria-label="Retry Estimate" class="madero-link u_clickable" (click)="purchaseService.getEstimate()">Retry</a>
  </div>
  <p [shown]="isSubcompanySelected">
    <b>Purchasing for: {{selectedCompany.name}}</b><br>
    {{selectedCompany.id}}
  </p>
  <div class="border-bottom py-4">
    @if (purchase.volumePlan?.licenses) {
      <div class="mb-4">
        <p class="mb-0">
          <span class="font-weight-bold">{{purchase.volumePlan.name}}:</span>
          <span class="pull-right">{{purchase.estimate.volumeSubtotal/100 | currency}}</span>
        </p>
        <p class="mb-0" [shown]="purchase.volumePlan.licenses">
          <span>{{((purchase.estimate.volumeSubtotal/100) / purchase.volumePlan.licenses / (purchase.volumePlan.isMonthly ? 1 : 12)) | currency}} per display license, per month.</span>
          <span class="pull-right">
            <a aria-label="Edit Subscription Details" class="madero-link u_clickable" (click)="purchaseStepsService.setCurrentStep(0)" tabindex="1">Edit</a>
          </span>
        </p>
      </div>
    }
    @if (purchase.unlimitedPlan?.licenses) {
      <div class="mb-4">
        <p class="mb-0">
          <span class="font-weight-bold">{{purchase.unlimitedPlan.name}}:</span>
          <span class="pull-right">{{purchase.estimate.unlimitedSubtotal/100 | currency}}</span>
        </p>
        <p class="mb-0" [shown]="purchase.unlimitedPlan.licenses">
          <span>{{((purchase.estimate.unlimitedSubtotal/100) / purchase.unlimitedPlan.licenses / (purchase.unlimitedPlan.isMonthly ? 1 : 12)) | currency}} per month (unlimited displays).</span>
          <span class="pull-right">
            <a aria-label="Edit Subscription Details" class="madero-link u_clickable" (click)="purchaseStepsService.setCurrentStep(0)" tabindex="1">Edit</a>
          </span>
        </p>
      </div>
    }
  </div>
  <p class="mb-4" [shown]="!addCoupon && !purchase.couponCode">
    &nbsp;
    <span class="pull-right">
      <a aria-label="Edit Subscription Details" class="madero-link u_clickable" (click)="addCoupon = true" tabindex="1">Add A Coupon Code</a>
    </span>
  </p>
  <div class="row" [shown]="addCoupon">
    <div class="col-md-12">
      <div class="form-group">
        <label for="coupon-code" class="control-label">Coupon Code:</label>
        <span class="pull-right">
          <a aria-label="Cancel Coupon Code" class="madero-link u_clickable" (click)="clearCouponCode()" tabindex="1">Cancel</a>
        </span>
        <div class="flex-row">
          <input id="coupon-code" aria-required="false" type="text" class="form-control mr-3" name="couponCode" [(ngModel)]="purchase.couponCode" (keyup.enter)="applyCouponCode()">
          <button id="apply-coupon-code" type="button" aria-label="Apply Coupon Code" class="btn btn-default" (click)="applyCouponCode()">Apply</button>
        </div>
      </div>
    </div>
  </div>
  <div class="border-bottom pb-4">
    @for (coupon of purchase.estimate.coupons; track coupon) {
      <p class="mb-0">
        <span class="font-weight-bold">{{coupon.description}}:</span>
        <span class="pull-right">-{{coupon.amount/100 | currency}}</span>
      </p>
    }
    @for (tax of purchase.estimate.taxes; track tax) {
      <p class="mb-0">
        <span>{{tax.description}}:</span>
        <span class="pull-right">{{tax.amount/100 | currency}}</span>
      </p>
    }
    <p class="mb-0">
      <span class="font-weight-bold">Total tax:</span>
      <span class="pull-right">{{purchase.estimate.totalTax/100 | currency}}</span>
    </p>
  </div>
  <div class="py-4">
    <p class="mb-0">
      <span class="font-weight-bold">Total cost per {{purchase.volumePlan.isMonthly ? 'month' : 'year'}}:</span>
      <span class="pull-right"><span class="u_margin-right text-subtle">{{purchase.estimate.currency | uppercase}}</span><span class="purchase-total">{{purchase.estimate.total/100 | currency}}</span></span>
    </p>
  </div>
</div>
<p class="mt-3" [shown]="purchase.estimate.totalTax > 0 || taxExemptionService.taxExemption.show">
  &nbsp;
  <span class="pull-right flex-row">
    <mat-checkbox (click)="applyTaxExemption()" [ngModel]="taxExemptionService.taxExemption.show"></mat-checkbox>
    <span class="mr-3">I'm tax exempt.</span>
  </span>
</p>
<tax-exemption class="u_margin-md-top"></tax-exemption>

<ng-template #overridesPopover>
  @for (override of scheduleOverrides; track override) {
    <span class="u_block">{{ override }}</span>
  }
</ng-template>
<div>
  <div class="flex-row layout-md-folded">
    <search-filter class="w-100" [filterConfig]="filterConfig" [search]="search" [doSearch]="displays.doSearch" iconSet="madero"></search-filter>
    @if (enableSubcompanies) {
      <div class="flex-row row-wrap-xs ml-md-3 mt-3 mt-md-0">
        <mat-checkbox class="mr-0" id="allCompaniesCheckbox" ngDefaultControl (ngModelChange)="toggleAllCompanies($event)" [ngModel]="distributeToSubcompanies" [disabled]="subcompanyDistributionDisabled"></mat-checkbox>
        <label class="font-weight-normal u_nowrap mb-0" [ngClass]="{ 'u_clickable': !subcompanyDistributionDisabled }" (click)="toggleAllCompanies(!distributeToSubcompanies)"> Select displays from all companies</label>
        <a href="#" uiSref="apps.purchase.home" (click)="closeEvent.emit()" class="madero-link u_nowrap u_inline-block ml-5 ml-sm-3" [shown]="subcompanyDistributionAvailable === null">Subscription Required</a>
        <a href="#" (click)="showUpgradePlanModal()" class="madero-link u_nowrap u_inline-block ml-5 ml-sm-3" [shown]="subcompanyDistributionAvailable !== null && !subcompanyDistributionAvailable">Plan Upgrade Required</a>
      </div>
    }
  </div>
  <div class="scrollable-list horizontal-scroll border-container u_margin-sm-top"
    scrollingList (scrollEndEvent)="displays.load()" (scroll)="refresh()"
    rvSpinner="display-list-loader" [rvShowSpinner]="displays.loadingItems"
    [shown]="!(!displays.loadingItems && displays.items.list.length === 0 && search.query === undefined)">
    <table id="displayListTable" class="table">
      <thead class="table-header">
        <tr class="table-header__row">
          <th id="tableHeaderName" (click)="displays.sortBy('name')" class="table-header__cell u_clickable">
            Name
            @if (search.sortBy === 'name') {
              <i class="fa" [ngClass]="{false: 'fa-caret-up', true: 'fa-caret-down'}[search.reverse]"></i>
            }
          </th>
          @if (enableSubcompanies) {
            <th id="tableHeaderCompanyName" class="table-header__cell">
              Company
            </th>
          }
          <th id="tableHeaderLicensed" class="table-header__cell">
            Licensed
          </th>
          <th id="tableHeaderLicensed" class="table-header__cell">
            Status
          </th>
          <th id="tableHeaderLastConnection" class="table-header__cell">
            Last Connection
          </th>
          <th id="tableHeaderSchedule" class="table-header__cell">
            Schedule
          </th>
        </tr>
      </thead>

      <tbody class="table-body">
        @for (display of displays.items.list; track display) {
          <tr class="table-body__row display">
            <td id="displayName-{{display.id}}" class="table-body__cell display-name">
              <div class="flex-row">
                <mat-checkbox class="mr-0" (click)="toggleDisplay(display.id, display.companyId);" [ngModel]="isSelected(display.id)"></mat-checkbox>
                <a class="madero-link u_clickable u_ellipsis-lg" (click)="toggleDisplay(display.id, display.companyId);"><strong>{{display.name}}</strong></a>
              </div>
            </td>
            @if (enableSubcompanies) {
              <td id="companyName-{{display.id}}" class="table-body__cell"><span>{{display.companyName}}</span></td>
            }
            <td id="licensed-{{display.id}}" class="table-body__cell"><span>{{display.playerProAuthorized ? 'Yes' : 'No'}}</span></td>
            <td id="status-{{display.id}}" class="table-body__cell">
              @if (displayService.showLicenseRequired(display) && display.onlineStatus !== 'not_activated') {
                <span>
                  License Required
                </span>
              }
              @else {
                <span [ngClass]="{'text-success': display.onlineStatus === 'online', 'text-danger': display.onlineStatus === 'offline'}">
                  {{display.onlineStatus | displayStatus}}
                </span>
              }
            </td>
            <td id="lastConnection-{{display.id}}" class="table-body__cell display-address">
              @if (displayService.showLicenseRequired(display)) {
                <span>
                  License Required
                </span>
              }
              @else {
                <span>
                  {{display.lastActivityDate | date:'d-MMM-yyyy h:mm a'}}
                </span>
              }
            </td>
            <td id="schedule-{{display.id}}" class="table-body__cell display-schedule">
              <div class="u_nowrap flex-row">
                @if (displayService.hasSchedule(display)) {
                  <span class="u_ellipsis-md schedule-view">
                    {{display.scheduleName}}
                  </span>
                }
                @else {
                  <span class="u_ellipsis-md">
                    {{ display.overrideScheduleNames ? 'No Default Schedule' : 'No Schedule Selected' }}
                  </span>
                }
                @if (display.overrideScheduleNames) {
                  <span class="badge badge-primary u_clickable ml-2"
                    [popover]="overridesPopover"
                    (onShown)="showPopover(display.overrideScheduleNames)"
                    containerClass="madero-style"
                    outsideClick="true"
                    placement="bottom right">
                    +{{display.overrideScheduleNames.length}}
                  </span>
                }
              </div>
            </td>
          </tr>
        }
        <!-- If no search results -->
        @if (!displays.loadingItems && displays.items.list.length === 0 && search.query !== undefined) {
          <tr class="table-body__row">
            <td colspan="3" class="text-center">Your search didn't return any results.</td>
          </tr>
        }
      </tbody>

    </table>
  </div><!--panel-->
</div>

<div class="border-container text-center u_margin-md-top u_margin-md-bottom" [shown]="!displays.loadingItems && displays.items.list.length === 0 && search.query === undefined">
  <div class="panel-body">
    <div class="form-group my-4">
      Add a display to start communicating better with your audience!
    </div>
    <a id="displayAddButton" class="btn btn-primary btn-toolbar-wide mb-2" (click)="closeEvent.emit()" uiSref="apps.displays.add">
      Add Display
    </a>
  </div>
</div>

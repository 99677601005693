<div id="playlistItemModal">
  <div class="modal-header">
    <button type="button" class="close" (click)="dismiss()" aria-label="Click to close this modal">
      <streamline-icon ariaLabel="Click to close this modal" name="close" width="12" height="12"></streamline-icon>
    </button>
    <h4 class="modal-title">{{isNew ? 'Add' : 'Edit' }} Playlist Item</h4>
  </div>

  <div class="modal-body align-left" rvSpinner="playlist-item-modal-loader" [rvShowSpinner]="loadingTemplate">
    <form [formGroup]="playlistItemForm" id="forms.playlistItemForm" role="form" name="forms.playlistItemForm" (keyup.enter)="save()" novalidate>

      @if (_playlistItem.type !== 'presentation') {
        <div class="form-group">
          <label class="control-label">Name:</label>
          <mat-form-field appearance="outline">
            <input type="text" matInput formControlName="itemName" id="itemName" placeholder="Item Name" [(ngModel)]="_playlistItem.name">
          </mat-form-field>
        </div>
      }

      @if (_playlistItem.type === 'url') {
        <div class="form-group">
        <label class="control-label">URL:</label>
        <rv-url-field
            id="url"
            formControlName="objectReference"
            placeholder="https://"
            [(ngModel)]="_playlistItem.objectReference"
            [acceptedFileTypes]="'ALL'" />
        </div>
      }

      @if (_playlistItem.type === 'presentation') {
        <div class="form-group">
          <label class="control-label u_margin-right">Presentation:</label>
          <a class="u_clickable" id="presentationName" (click)="selectPresentation()" [presentationName]="_playlistItem.objectReference"></a>
          <br>
          @if(_playlistItem.presentationType === 'HTML Template') {
            <a id="htmlPresentationLink" uiSref="apps.editor.templates.edit" [uiParams]="{presentationId: _playlistItem.objectReference}" (click)="dismiss()">Edit Presentation</a>
          } @else {
            <a id="presentationLink" uiSref="apps.editor.workspace.artboard" [uiParams]="{presentationId: _playlistItem.objectReference}" (click)="dismiss()">Edit Presentation</a>
          }
        </div>
      }

      <div class="form-group u_margin-sm-top">
        <label class="control-label" for="playlist-item-pud">Duration:</label>

        @if (_playlistItem.type === 'presentation' && playUntilDoneSupported) {
          <div class="flex-row">
            <mat-checkbox formControlName="playUntilDone" [(ngModel)]="_playlistItem.playUntilDone" ngDefaultControl>Play Until Done</mat-checkbox>
          </div>
        }
      </div>

      @if (!_playlistItem.playUntilDone) {
        <div>
          <mat-form-field appearance="outline">
            <input matInput type="number" formControlName="duration" id="duration" [(ngModel)]="_playlistItem.duration" required min="1" placeholder="Enter duration">
            @if (f['duration'].errors?.required) {
              <mat-error>Required</mat-error>
            }
            <span matTextSuffix>Seconds</span>
          </mat-form-field>
        </div>
      }

      <div class="form-group mt-4">
        <label class="control-label">Timeline:</label>
        <timeline-textbox [timelineObject]="_playlistItem" timelineStyle="madero"></timeline-textbox>
      </div>

      <div class="form-group">
        <label class="control-label">Transition:</label>
        <mat-form-field appearance="outline">
          <select matNativeControl formControlName="transitionType" [(ngModel)]="_playlistItem.transitionType">
            @for (transitionType of transitionTypes; track transitionType[0]) {
              <option [value]="transitionType[0]">{{transitionType[1]}}</option>
            }
          </select>
        </mat-form-field>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-default btn-toolbar" (click)="dismiss()">Cancel</button>
    <button type="button" class="btn btn-primary btn-toolbar" id="itemSave" (click)="save()" [disabled]="saveDisabled()">{{isNew ? 'Add' : 'Apply'}}</button>
  </div>
</div>

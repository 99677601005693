import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from "@angular/forms";
import { MatTooltipModule } from '@angular/material/tooltip';
import { UIRouterModule } from '@uirouter/angular';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { ComponentsModule } from '../components/components.module';
import { NotificationAlertComponent } from '../components/notification-alert/notification-alert.component';
import { CardDescriptionPipe } from '../purchase/pipes/card-description.pipe';
import { PurchaseModule } from '../purchase/purchase.module';
import { SharedModule } from '../shared/shared.module';
import { routes } from './billing.route';
import { AddPaymentSourceComponent } from './components/add-payment-source/add-payment-source.component';
import { BillingComponent } from './components/billing/billing.component';
import { EditPoNumberComponent } from './components/edit-po-number/edit-po-number.component';
import { InvoiceComponent } from './components/invoice/invoice.component';
import { ShareUrlButtonComponent } from './components/share-url-button/share-url-button.component';
import { StatusNameComponent } from './components/status-name/status-name.component';
import { SubscriptionComponent } from './components/subscription/subscription.component';
import { UnpaidInvoicesComponent } from './components/unpaid-invoices/unpaid-invoices.component';
import { CountryNamePipe } from './pipes/country-name.pipe';
import { SubscriptionDescriptionPipe } from './pipes/subscription-description.pipe';
import { ViewportComponent } from './viewport/viewport.component';
import { MatRadioModule } from '@angular/material/radio';

@NgModule({
  declarations: [
    BillingComponent,
    SubscriptionComponent,
    SubscriptionDescriptionPipe,
    CountryNamePipe,
    EditPoNumberComponent,
    AddPaymentSourceComponent,
    InvoiceComponent,
    UnpaidInvoicesComponent,
    ShareUrlButtonComponent,
    ViewportComponent,
    StatusNameComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    UIRouterModule.forChild(routes),
    ComponentsModule,
    SharedModule,
    PurchaseModule,
    TooltipModule,
    MatTooltipModule,
    NotificationAlertComponent,
    MatRadioModule
  ],
  providers: [
    CardDescriptionPipe
  ]
})
export class BillingModule {
  //workaround for including downgraded components into build files
  //https://github.com/angular/angular/issues/35314#issuecomment-584821399
  static entryComponents = [ ];

}

<div class="attribute-editor-component">
  <div class="row attribute-editor-row mb-4" [shown]="!sharedTemplatesAvailable">
    @if (sharedTemplatesAvailable === null) {
      <div>
        <a href="#" class="madero-link" uiSref="apps.purchase.home">Subscription Required</a>
      </div>
    }
    @else {
      <div>
        <a href="#" class="madero-link" (click)="showUpgradePlanModal()">Plan Upgrade Required</a>
      </div>
    }
  </div>
  <div class="row attribute-editor-row">
    <div class="col" [ngClass]="{ 'u_link-disabled': !sharedTemplatesAvailable }">
      <label class="text-sm mb-3">
        This company and its sub-companies can use this presentation as a template
      </label>
    </div>
  </div>
  <div class="row attribute-editor-row">
    <mat-radio-group 
      class="tw-flex tw-flex-col"
      id="shareRadio"
      [disabled]="!sharedTemplatesAvailable"
        [(ngModel)]="shareTemplate" 
        name="shareTemplateRadio"
        (change)="save()">
        <mat-radio-button 
          [value]="true" 
          id="shareTemplateYes"
          [disabled]="companyStateService.isRootCompanySelected() || !sharedTemplatesAvailable">
          <span class="font-weight-bold">Yes</span>
        </mat-radio-button>
        <mat-radio-button 
          [value]="false" 
          id="shareTemplateNo"
          [disabled]="companyStateService.isRootCompanySelected() || !sharedTemplatesAvailable">
          <span class="font-weight-bold">No</span>
        </mat-radio-button>
    </mat-radio-group>
  </div>
</div>

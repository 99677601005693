<mat-form-field appearance="outline">
  <input
    matInput
    type="text"
    [attr.aria-label]="'Color picker'"
    placeholder="Enter a color..."
    id="{{pickerId}}"
    #colorInput
    (ngModelChange)="onColorChange($event)"
    [(ngModel)]="color"
    [disabled]="disabled">
</mat-form-field>
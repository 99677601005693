import { Component, Input } from '@angular/core';

/**
 * Displays an alert notification with optional title and message
 * @example
 * <notification-alert
 *   type="warning"
 *   title="Certificate Expired"
 *   message="Your tax exemption certificate has expired."
 * ></notification-alert>
*/
@Component({
  selector: 'notification-alert',
  standalone: true,
  imports: [],
  templateUrl: './notification-alert.component.html',
  styleUrl: './notification-alert.component.scss'
})
export class NotificationAlertComponent {
  @Input() message: string;
  @Input() type: 'info' | 'success' | 'warning' | 'danger' = 'info';
  @Input() title: string;

  protected alertTypeIconClass = {
    info: 'fa-exclamation-circle rise-blue',
    success: 'fa-exclamation-circle rise-green',
    warning: 'fa-exclamation-circle text-warning',
    danger: 'fa-times-circle text-danger'
  };
}

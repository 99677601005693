import { Injectable } from '@angular/core';

import { AnalyticsFactory, ProcessErrorCode, ProxyFileDownloader } from 'src/app/ajs-upgraded-providers';

import { CreditCardService } from 'src/app/purchase/services/credit-card.service';
import { BillingApiService } from 'src/app/api/services/billing-api.service';
import { UIRouterGlobals } from '@uirouter/angular';
import { UserStateService } from 'src/app/auth/services/user-state.service';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';
import { StoreApiService } from 'src/app/api/services/store-api.service';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  invoice;
  invoiceToken: string;
  loading;
  apiError;

  constructor(
    private uiRouterGlobals: UIRouterGlobals,
    private userStateService: UserStateService,
    private companyStateService: CompanyStateService,
    private analyticsService: AnalyticsFactory,
    private billingApiService: BillingApiService,
    private storeApiService: StoreApiService,
    private creditCardService: CreditCardService,
    private proxyFileDownloader: ProxyFileDownloader,
    private processErrorCode: ProcessErrorCode) { }

  _clearMessages() {
    this.loading = false;

    this.apiError = '';
  }

  init(initCreditCards) {
    this._clearMessages();

    if (initCreditCards) {
      this.creditCardService.initPaymentMethods(true);
    }
  }

  _getCompanyId() {
    return this.uiRouterGlobals.params.cid || this.companyStateService.getSelectedCompanyId();
  }

  generateToken(): void {
    if (this.uiRouterGlobals.params.token) {
      this.invoiceToken = this.uiRouterGlobals.params.token.replaceAll(" ", "+");
      return;
    }
    this.invoiceToken = '';

    const company = this.companyStateService.getCopyOfSelectedCompany();
    const authKey = company && company.authKey;

    if (authKey) {
      crypto.subtle.digest('SHA-256', new TextEncoder().encode(authKey)).then((digest) => {
        this.invoiceToken = btoa(String.fromCharCode.apply(null, new Uint8Array(digest)));
      });
    }
  }

  getInvoice(invoiceId, companyId, token) {
    this.init(true);

    this.invoice = {};
    this.loading = true;

    return this.billingApiService.getInvoice(invoiceId, companyId, token)
      .then((resp) => {
        this.invoice = resp.item;
      })
      .catch((e) => {
        this._showErrorMessage(e);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  updateInvoice() {
    this._clearMessages();

    this.loading = true;

    return this.billingApiService.updateInvoice(this.invoice, this._getCompanyId(), this.invoiceToken)
      .then((resp) => {
        this.invoice = resp.item;
      })
      .catch((e) => {
        this._showErrorMessage(e);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  _preparePaymentIntent() {
    var paymentMethodId = this.creditCardService.getPaymentMethodId();

    return this.storeApiService.preparePayment(paymentMethodId, this.invoice.id,
        this._getCompanyId(), this.invoiceToken)
      .then((response) => {
        if (response.error) {
          return Promise.reject(response.error);
        } else {
          this.creditCardService.paymentMethods.intentResponse = response;
          if (response.authenticationRequired) {
            return this.creditCardService.handleCardAction(response.intentSecret);
          } else {
            return Promise.resolve();
          }
        }
      });
  }

  _completePayment() {
    var paymentIntentId = this.creditCardService.paymentMethods.intentResponse ?
      this.creditCardService.paymentMethods.intentResponse.intentId : null;

    return this.storeApiService.collectPayment(paymentIntentId, this.invoice.id,
        this._getCompanyId(), this.invoiceToken)
      .then(() => {
        var originalAmountDue = this.invoice.amount_due;

        this.invoice.status = 'paid';
        this.invoice.amount_paid = this.invoice.total;
        this.invoice.amount_due = 0;

        this.analyticsService.track('Invoice Paid', {
          invoiceId: this.invoice.id,
          currency: this.invoice.currency_code,
          amount: originalAmountDue / 100,
          userId: this.userStateService.getUsername(),
          companyId: this.invoice.customer_id
        });

        //   this.purchase.reloadingCompany = true;
        //
        //   $timeout(10000)
        //     .then(() => {
        //       return this.userStateService.reloadSelectedCompany();
        //     })
        //     .then(() => {
        //       $rootScope.$emit('risevision.company.planStarted');
        //     })
        //     .catch((err) => {
        //       console.debug('Failed to reload company', err);
        //     })
        //     .finally(() => {
        //       this.purchase.reloadingCompany = false;
        //     });
      });
  }

  payInvoice() {
    this._clearMessages();

    this.loading = true;

    this.creditCardService.validatePaymentMethod()
      .then(this._preparePaymentIntent.bind(this))
      .then(this._completePayment.bind(this))
      .catch((e) => {
        this._showErrorMessage(e);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  downloadInvoice(invoiceId) {
    this._clearMessages();

    this.loading = true;

    this.billingApiService.getInvoicePdf(invoiceId, this._getCompanyId(), this.invoiceToken)
      .then((resp) => {
        if (resp && resp.result) {
          // Trigger download
          this.proxyFileDownloader(resp.result, 'Rise Vision Invoice - ' + invoiceId + '.pdf');
        }
      })
      .catch((e) => {
        this._showErrorMessage(e);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  _showErrorMessage(e) {
    this.apiError = this.processErrorCode(e);

    console.error(this.apiError, e);
  }

}

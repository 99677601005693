import { Directive, OnDestroy } from "@angular/core";
import { NgControl } from "@angular/forms";
import { UrlFieldValidators } from "../url-field-validators";

@Directive({
  selector: '[httpValidator]',
  standalone: true
})
export class HttpValidatorDirective implements OnDestroy {

  constructor(private readonly ngControl: NgControl) {
    try {
      this.ngControl?.control?.addValidators(UrlFieldValidators.http);
    } catch (error) {
      console.error('Failed to register HTTP validator:', error);
    }
  }
  
  ngOnDestroy(): void {
    try {
      this.ngControl?.control?.removeValidators(UrlFieldValidators.http);
    } catch (error) {
      console.error('Failed to remove HTTP validator:', error);
    }
  }
}
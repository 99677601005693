<div>
  <div class="app-header u_padding-20-vertical mb-5 border-bottom">
    <!-- App Title -->
    <h1 class="app-header-title">Subscription</h1>
  </div>
  <div rvSpinner="subscription-loader" [rvShowSpinner]="subscriptionService.loading || taxExemptionFactory.loading || paymentSourcesFactory.loading">
    <div id="errorBox" [shown]="subscriptionService.apiError" class="madero-style alert alert-danger" role="alert">
      <strong>{{subscriptionService.apiError}}</strong>
    </div>
    <div id="errorBox" [shown]="paymentSourcesFactory.apiError" class="madero-style alert alert-danger" role="alert">
      <strong>{{paymentSourcesFactory.apiError}}</strong>
    </div>
    <h4 class="u_margin-md-bottom">
      {{itemSubscription | subscriptionDescription}} —
      <status-name [subscription]="itemSubscription"></status-name>
    </h4>
    @if (billingUtils.hasPendingChange(itemSubscription)) {
      <pending-change-alert></pending-change-alert>
    }
    <div class="row subscription-card-row">
      <div class="col-md-4 col-xs-12">
        <div class="border-container subscription-card">
          <div class="panel-body">
            <label>{{getPlanName(itemSubscription)}}</label>
            <h1 class="mb-3" [shown]="isVolumePlan(itemSubscription)">{{ displayCount }}</h1>
          </div>
          <div class="panel-footer" [shown]="itemSubscription.status === 'active'">
            <span [shown]="isDisplayLicensePlan(itemSubscription)">
              <a id="addLicenses" class="btn btn-default btn-block mt-0" uiSref="apps.purchase.licenses.add" [uiParams]="{subscriptionId: itemSubscription.id}">Add Display Licenses</a>
              <a id="removeLicenses" class="btn btn-default btn-block mt-4" uiSref="apps.purchase.licenses.remove" [uiParams]="{subscriptionId: itemSubscription.id}" [hidden]="itemSubscription.plan_quantity < 2">Remove Display Licenses</a>
              @if (canAccessUnlimitedPlan && itemSubscription.plan_quantity < 10 && !planType) {
                <a id="unlimitedLicenses" class="btn btn-primary btn-block mt-4" uiSref="apps.purchase.licenses.unlimited" [uiParams]="{subscriptionId: itemSubscription.id}">Upgrade To Unlimited</a>
              }
              @if (planType && planType !== 'unlimited') {
                <a id="changePlan" class="btn btn-default btn-block mt-4" uiSref="apps.purchase.change-plan" [uiParams]="{ subscriptionId: itemSubscription.id, planType: planType, displayCount: displayCount }">Change My Plan</a>
              }
            </span>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-xs-12">
        <div class="border-container subscription-card">
          <div class="panel-body">
            <label>Plan Amount</label>
            <h1 class="mb-4">{{itemSubscription.plan_amount / 100 | currency}}</h1>
            @if (itemSubscription.current_term_end && itemSubscription.status !== 'non_renewing') {
              <p>
                <b>Renewing On:</b> {{itemSubscription.current_term_end * 1000 | date:'d-MMM-yyyy'}}
              </p>
            }
            @if (itemSubscription.billing_period_unit) {
              <p>
                <b>Billing Frequency:</b> {{itemSubscription.billing_period_unit === 'month' ? 'Monthly' : itemSubscription.billing_period === 1 ? 'Annual' : itemSubscription.billing_period + ' Years'}}
              </p>
            }
            <p>
              <a class="madero-link" href="#" (click)="helpWidgetFactory.showContactUs()">Contact Us</a> for a renewal quote.
            </p>
          </div>
          <div class="panel-footer">
            <a id="switchToAnnual" class="btn btn-block mt-0" [ngClass]="{ 'btn-default': canAccessUnlimitedPlan, 'btn-primary': !canAccessUnlimitedPlan }" uiSref="apps.purchase.frequency" [uiParams]="{subscriptionId: itemSubscription.id}" [shown]="itemSubscription.billing_period_unit === 'month' && planType && itemSubscription.status !== 'non_renewing'">Switch To Annual Billing (Save 10%)</a>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-xs-12">
        <div class="border-container subscription-card">
          <div class="panel-body">
            <label class="mb-3">Payment Method</label>
            <mat-radio-group [(ngModel)]="subscriptionService.items[0].paymentSourceId" name="paymentSourceId">
              @for (card of paymentSourcesFactory.existingCreditCards; track card) {
                <div class="flex-row madero-radio align-left mb-3">
                  <div class="row-entry tw-justify-between">
                    <mat-radio-button
                      name="paymentSourceId"
                      [value]="card.payment_source.id"
                      [id]="card.payment_source.id"
                      (click)="subscriptionService.changePaymentMethod($event, card)"
                      tabindex="1">
                      <span class="font-weight-bold">
                        {{card.payment_source.card | cardDescription}}
                      </span>
                    </mat-radio-button>
                    <a class="madero-link u_clickable text-danger font-weight-bold" 
                       (click)="paymentSourcesFactory.removePaymentMethod(card)" 
                       [shown]="showCardRemove(card)">Remove</a>
                  </div>
                </div>
              }
              <div [shown]="subscriptionService.isInvoiced()" class="flex-row madero-radio align-left mb-3">
                <div class="row-entry tw-justify-between">
                  <mat-radio-button
                    name="invoiceMe"
                    value="invoice" 
                    id="invoiceMe"
                    tabindex="1">
                    <span class="font-weight-bold">Invoice Me</span>
                  </mat-radio-button>
                </div>
              </div>
            </mat-radio-group>
            <div class="align-left mt-0" [shown]="subscriptionService.isInvoiced()">
              <edit-po-number [item]="subscriptionService.items[0]?.subscription" [updateFunction]="changePoNumber"></edit-po-number>
            </div>
          </div>
          <div class="panel-footer">
            <a id="addPaymentMethod" class="btn btn-default btn-block mt-0" uiSref="apps.billing.payment" [uiParams]="{subscriptionId: itemSubscription.id}">Add Payment Method</a>
          </div>
        </div>
      </div>
    </div>
    <div class="row subscription-card-row">
      <div class="col-md-8 col-xs-12">
        <div class="border-container subscription-card">
          <div class="panel-body">
            <label class="mb-3">Company Billing Details</label>
            <span class="pull-right"><a class="madero-link u_clickable" uiSref="apps.company.details">Edit</a></span>
            <div class="align-left">
              <div class="row mb-3" [shown]="subscriptionService.getItemCustomer().billing_address?.first_name || subscriptionService.getItemCustomer().billing_address?.last_name">
                <div class="col-md-3">
                  <label class="mb-0">Name:</label>
                </div>
                <div class="col-md-9">
                  {{subscriptionService.getItemCustomer().billing_address?.first_name}} {{subscriptionService.getItemCustomer().billing_address?.last_name}}
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-md-3">
                  <label class="mb-0">Company Name:</label>
                </div>
                <div class="col-md-9">
                  {{company.name}}
                </div>
              </div>
              <div class="row mb-3" [shown]="company.telephone">
                <div class="col-md-3">
                  <label class="mb-0">Phone:</label>
                </div>
                <div class="col-md-9">
                  {{company.telephone}}
                </div>
              </div>
              <div class="row">
                <div class="col-md-3">
                  <label class="mb-0">Address:</label>
                </div>
                <div class="col-md-9">
                  <p class="mb-0">{{company.street}} <span [shown]="company.unit">{{company.unit}}</span></p>
                  <p class="mb-0">{{company.city}}, {{company.province}} {{company.postalCode}}</p>
                  <p class="mb-0">{{company.country | countryName}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-xs-12">
        <div class="border-container subscription-card">
          <div class="panel-body">
            <label class="mb-3">Tax Exemption</label>
            @if (subscriptionService.isTaxExemptionExpired && !taxExemptionFactory.taxExemption.sent) {
              <notification-alert 
                title="Tax exemption expired"
                message="Please update your tax exemption certificate."
                type="warning"
              />
            } @else if (subscriptionService.isTaxExemptionExpired && taxExemptionFactory.taxExemption.sent) {
              <notification-alert 
                title="Certificate uploaded!"
                message="The certificate will be processed within 1 business day."
              />
            } @else {
              <p [shown]="subscriptionService.getItemCustomer().taxability !== 'taxable'">Tax exemption applied.</p>
            }
            <tax-exemption class="align-left" [showCancel]="true"></tax-exemption>
          </div>
          <div class="panel-footer">
            <button id="addTaxExemption"
              class="btn btn-default btn-block mt-0"
              (click)="taxExemptionFactory.taxExemption.show = true"
              [shown]="shouldShowTaxExemptionButton">I'm Tax Exempt</button>
          </div>
        </div>
      </div>
    </div>
    <div class="u_margin-lg subscription-footer">
      <label>
        <streamline-icon aria-hidden="true" name="help" width="20" height="20"></streamline-icon>
        Need to cancel your subscription?
      </label>
      <p><a class="madero-link" href="#" (click)="helpWidgetFactory.showContactUs()">Contact Us</a></p>
    </div>
  </div>
</div>

<div class="attribute-editor-component">
  <div class="attribute-editor-row" [ngClass]="{ 'has-error': !hasValidAddress, 'has-success': hasValidAddress }">
    <p class="help-block" [shown]="!hasValidAddress && !canEditCompany">Set an address to see the weather. Please contact your System Administrator to set an address to your company.</p>
    <p class="help-block" [shown]="!hasValidAddress && canEditCompany">Set an address to see the weather. Navigate to <a class="madero-link" uiSref="apps.company.details">Company Settings</a>, enter your address and click Save.</p>
    <p [shown]="hasValidAddress">This Presentation will show the weather for the Address of the Display it is playing on.</p>
    <label class="u_margin-sm-top">Display weather in:</label>
    <mat-radio-group class="tw-flex tw-flex-col" [(ngModel)]="scale" (change)="save()" name="temperatureUnit">
      <mat-radio-button 
        value="F" 
        id="fahrenheit" 
        tabindex="1">
        <span class="font-weight-bold">Fahrenheit</span>
      </mat-radio-button>
      <mat-radio-button 
        value="C" 
        id="celsius"
        tabindex="1">
        <span class="font-weight-bold">Celsius</span>
      </mat-radio-button>
    </mat-radio-group>
  </div>
</div>

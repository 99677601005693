import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'background-image',
  templateUrl: `./background-image.component.html`,
  styleUrls: ['./background-image.component.scss']
})
export class BackgroundImageComponent implements OnChanges {

  protected imageUrl: string;
  protected imageLoaded = false;


  @Input() background = {
    useImage: false,
    image: {
      url: '',
      position: 'top-left',
      scale: true,
      repeat: 'no-repeat'
    },
    color: ''
  };
  @Input() companyId: string;

  constructor() {
  }

  ngOnChanges() {
    if (this.background.useImage && this.background.image && this.background.image.url)
      this.setImageUrl(this.background.image.url);
  }

  protected useImageChange(value: boolean) {
    this.background.useImage = value;
    if (this.background.useImage && !this.background.image)
      this.setDefaultImageObject();
  }

  protected setImageUrl(url: string) {
    if (!this.background.image) this.setDefaultImageObject();
    this.background.image.url = url;
    this.imageUrl = url;
    this.imageLoaded = true;
  }

  private setDefaultImageObject() {
    this.background.image = {
      url: '',
      position: 'top-left',
      scale: true,
      repeat: 'no-repeat'
    };
  }

}

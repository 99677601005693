import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AbstractControl } from "@angular/forms";
import { catchError, map, of } from "rxjs";
import { StorageUtilsService } from "src/app/storage/services/storage-utils.service";
import { ComponentUtilsService } from "src/app/template-editor/template-components/services/component-utils.service";

export class UrlFieldValidators {
  static http(control: AbstractControl<string>) {
    return control?.value && control.value.startsWith('http://')
      ? { httpUrl: true }
      : null;
  }

  static responseHeader(httpClient: HttpClient) {
    return (control: AbstractControl) => {
      if (control.invalid) return of(null);
      if (!control.value?.trim()) return of(null);
      return httpClient.head(
        'https://services2.risevision.com/proxy/' + control.value,
        { 
          headers: { Accept: 'image/*, video/*, text/*, audio/*' },
          observe: 'response'
        }
      ).pipe(
        catchError(() => of({ ok: false, headers: null })),
        map((response) => {
          if (!response.ok) return { responseHeader: 'not-reachable' };
          const options = {
            ...extractContentSecurityPolicy(response.headers),
            ...extractContentType(response.headers),
            ...extractXFrameOptions(response.headers),
          };
  
          return Object.keys(options).length ? options : null;
        })
      );
    };  
  }

  static url(control: AbstractControl) {
    if (!control?.value?.trim()) return null;
    return ComponentUtilsService.validateUrl(control?.value ?? '' )
      ? null
      : { url: true };
  }

  static file(acceptedFileTypes: keyof typeof StorageUtilsService.SELECTOR_FILTERS) {
    return (control: AbstractControl) => {
      if (!control?.value) return null;
      const extensions: string[] = 
        StorageUtilsService.SELECTOR_FILTERS[acceptedFileTypes].extensions || [];
      
      return hasValidExtension(
        control.value ?? '',
        extensions
      ) ? null : { fileType: {extensions: extensions.join(', ')} };
    };
  }

  static noPreviewUrl(control: AbstractControl) {
    if (!control.value) return null;
    const value = control.value.trim();
    
    if (value && (value.indexOf('preview.risevision.com') > -1 ||
      value.indexOf('widgets.risevision.com/viewer') > -1)) {
      return { noPreviewUrl: true };
    }
    return null;
  }
}

function extractXFrameOptions(headers: HttpHeaders) {
  const xFrameOptions = headers.get('x-frame-options');
  
  if (xFrameOptions && xFrameOptions.indexOf('ALLOW-FROM') === -1) {
    return { responseHeader: 'X-Frame-Options' }; 
  }
  return {};
}

function extractContentSecurityPolicy(headers: HttpHeaders) {
  const contentSecurityPolicy = headers.get('content-security-policy');
  
  if (contentSecurityPolicy && contentSecurityPolicy.includes('frame-ancestors')) {
    return { responseHeader: 'frame-ancestors' };
  }
  return {};
}

function extractContentType(headers: HttpHeaders) {
  const contentType = headers.get('content-type');
  
  if (contentType && !contentType.match('(image\/|video\/|text\/|audio\/).*')) {
    return { responseHeader: 'content-type' };
  }
  return {};
}

function hasValidExtension(url: string, allowedExtensions: string[]) {
  if (!allowedExtensions.length) return true;
  const testUrl = url.toLowerCase();
  
  for (let i = 0, len = allowedExtensions.length; i < len; i++) {
    if (testUrl.indexOf(allowedExtensions[i]) !== -1) {
      return true;
    }
  }
  return false;
}

<div
  class="attribute-editor-component youtube-component-container"
  rvSpinner="youtube-editor-loader"
  [rvShowSpinner]="spinner"
>
  <div class="attribute-editor-row">
    <form>
      <div
        class="form-group has-feedback"
        [ngClass]="{
          'has-error': validationStatus && validationStatus !== 'VALID',
          'has-success': validationStatus === 'VALID' && source !== ''
        }"
      >
        <label class="control-label" for="te-youtube-src">Source:</label>
        <input
          type="text"
          id="te-youtube-src"
          name="te-youtube-src"
          class="form-control u_ellipsis"
          [(ngModel)]="source"
          placeholder="Enter URL"
          (debounceEvent)="saveSource()"
          [disabled]="!termsAccepted"
        />
        <streamline-icon
          class="overlay-icon form-control-feedback"
          aria-hidden="true"
          name="{{
            validationStatus && validationStatus !== 'VALID'
              ? 'exclamation'
              : 'checkmark'
          }}"
          width="14"
          height="12"
        ></streamline-icon>
        <p class="help-block">
          @switch (validationStatus) {
            @case ('INVALID') {
              <span>Please provide a valid YouTube URL.</span>
            }
            @case ('VIDEO_NOT_FOUND') {
              <span>Video not found.</span>
            }
            @case ('PLAYLIST_NOT_FOUND') {
              <span>Playlist not found.</span>
            }
            @case ('CHANNEL_NOT_FOUND') {
              <span>Channel not found.</span>
            }
            @case ('NOT_EMBEDDABLE') {
              <span>Embedding of this Video is not allowed.</span>
            }
          }
        </p>
      </div>
    </form>
  </div>
  <div class="attribute-editor-row">
    <div id="youtube-component-volume">
      <label>Volume:</label>
      <div class="row">
        <div class="col-xs-12">
          <input
            min="0"
            max="100"
            type="range"
            [(ngModel)]="volume"
            (change)="saveVolume()"
            [disabled]="!termsAccepted"
          />
        </div>
      </div>
      <p class="range-value">{{ volume }}</p>
    </div>
    <p class="text-sm">
      Your video(s) won't be shown in preview but will play on your Display.
    </p>
  </div>
  @if (!termsAccepted) {
    <div class="terms-conditions py-3">
      <p class="px-3">
        This integration uses YouTube API Services. By using it, you agree to be bound by the
        <a class="madero-link" target="_blank" href="https://www.youtube.com/t/terms">YouTube Terms of Service</a>
        and <a class="madero-link" target="_blank" href="http://www.google.com/policies/privacy">Google Privacy Policy</a>.
        Rise Vision does not collect any personal data from YouTube.
      </p>
      <button type="button" class="btn btn-primary" (click)="acceptTerms()">Accept</button>
    </div>
  }
</div>

import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MessageType, ScreenSharingService } from '../../services/screen-sharing.service';
import { ModalService } from 'src/app/components/modals/modal.service';
import { Transition, UIRouterGlobals } from '@uirouter/angular';
import { UserStateService } from 'src/app/auth/services/user-state.service';
import { Subscription } from 'rxjs';
import { DisplayService } from 'src/app/displays/services/display.service';
import { RvshareAppDetectionService } from '../../services/rvshare-app-detection.service';

@Component({
  selector: 'moderator-room',
  templateUrl: './moderator-room.component.html',
  styleUrl: './moderator-room.component.scss'
})
export class ModeratorRoomComponent implements OnInit, OnDestroy {

  paused = false;
  stopped = true;
  joinedSubscription: Subscription;
  invitedSubscription: Subscription;
  displayName = '';

  constructor(
    protected screenSharingService: ScreenSharingService,
    private displayService: DisplayService,
    private modalService: ModalService,
    private transition: Transition,
    protected rvshareAppDetectionService: RvshareAppDetectionService,
    private uiRouterGlobals: UIRouterGlobals,
    private userStateService: UserStateService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    const displayId = this.uiRouterGlobals.params.displayId;
    if (displayId) {
      this.screenSharingService.join(displayId, this.userStateService.getUserFullName(), true);
      this.displayService.getDisplay(displayId).then(() => {
        this.displayName = this.displayService.display.name;
      });
    }

    this.joinedSubscription = this.screenSharingService.joinedSubject.subscribe((value) => {
      if (value === MessageType.JOINED_CHANNEL) {
        this.stopped = !this.screenSharingService.mediaStream;
      } else if (value === MessageType.ANSWER) {
        this.stopped = false;
      } else if (value === MessageType.CLIENT_STREAM_CLOSED) {
        this.stopped = true;
        this.changeDetectorRef.detectChanges();
      } else if (value === 'error') {
        this.redirect();
      }
    });
  }

  ngOnDestroy(): void {
    this.joinedSubscription?.unsubscribe();
    this.screenSharingService.leave();
  }

  redirect() {
    this.transition.router.stateService.go('apps.screen-sharing.moderator-join', { reload: true });
  }

  start() {
    this.screenSharingService.start();
    this.paused = false;
    this.stopped = true;
  }

  resume() {
    this.screenSharingService.stream();
    this.paused = false;
    this.stopped = false;
  }

  pause() {
    this.screenSharingService.pause();
    this.paused = true;
    this.stopped = false;
  }

  stop() {
    if (this.screenSharingService.memberIsSharing) {
      this.screenSharingService.stop();
      this.stopped = true;
    } else {
      this.screenSharingService.stopSharing();
    }
  }

  share(memberId: string) {
    if (memberId === this.screenSharingService.memberId) {
      this.screenSharingService.stopSharing();
      this.start();
    } else {
      this.screenSharingService.invite(memberId);
    }
  }

  remove(id: string) {
    if (id === this.screenSharingService.memberId) {
      this.modalService.confirmDanger('Leave room?',
        `Are you sure you want to leave the room? ${
          this.screenSharingService.moderatorCount === 1 ?
            'As you are the only moderator, the room will also be closed after a short delay.'
          : 'As you are not the only moderator, the room will remain open.'
        }`,
        'Leave', 'Cancel'
      ).then(() => {
        this.screenSharingService.leave();
        this.redirect();
      }).catch(() => {});
    } else {
      this.modalService.confirmDanger('Remove participant?',
        'The participant will be removed from the room\'s list. They can enter the session code to re-enter the room.',
        'Remove Participant', 'Cancel'
      ).then(() => {
        this.screenSharingService.remove(id);
      }).catch(() => {});
    }
  }

  end() {
    this.modalService.confirmDanger('End session for all?',
      'All participants, including the moderator, will be removed from the room and the sharing session will end.',
      'End Session for All', 'Cancel'
    ).then(() => {
      this.screenSharingService.resetChannel();
      this.redirect();
    }).catch(() => {});
  }
}

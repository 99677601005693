<div class="attribute-editor-component colors-container" >
    <div class="attribute-editor-row">
      <span class="colors-checkbox">
        <input id="overrideBranding" type="checkbox" (change)="save()" [(ngModel)]="override">
        <label tabindex="0" class="control-label" for="overrideBranding" (keyup.enter)="handleEnterKey()">Override brand colors</label>
      </span>
    </div>
  </div>

  <div id="branding-colors-override-container" class="attribute-list-container" [shown]="override">

    <label class="control-label" for="branding-colors-override-base">Base Color:</label>
    <p>Pick a dark color.</p>
    <color-picker pickerId="branding-colors-override-base" [(ngModel)]="baseColor" (change)="save()" ngDefaultControl></color-picker>

    <label class="control-label u_margin-md-top" for="branding-colors-override-accent">Accent Color:</label>
    <p>Pick a light color.</p>
    <color-picker pickerId="branding-colors-override-accent" [(ngModel)]="accentColor" (change)="save()" ngDefaultControl></color-picker>
  </div>


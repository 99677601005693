import { Injectable } from '@angular/core';

import { HttpClient, HttpParams } from '@angular/common/http';
import { CoreAPILoader } from 'src/app/ajs-upgraded-providers';
import { ApiService } from './api.service';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';
import { environment } from 'src/environments/environment';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StoreApiService extends ApiService<any> {

  protected APIUrl = environment.SPRING_STORE_ENDPOINT_URL + this.getResourceUrl();

  constructor(
    protected httpClient: HttpClient,
    protected companyStateService: CompanyStateService,
    private coreAPILoader: CoreAPILoader) {
      super(httpClient, companyStateService);
  }

  getResourceUrl(): string {
    return '/v2';
  }

  validateAddress (addressObject) {
    const params = new HttpParams()
      .set('street', addressObject.street)
      .set('unit', addressObject.unit)
      .set('city', addressObject.city)
      .set('country', addressObject.country)
      .set('postalCode', addressObject.postalCode)
      .set('province', addressObject.province);

    return firstValueFrom(super.requestAsObservable('', 'POST', 'validate_address', params));
  }

  estimatePurchase (jsonData) {
    const params = new HttpParams()
      .set('jsonData', jsonData);

    return firstValueFrom(super.requestAsObservable('', 'GET', 'purchase/estimate', params));
  }

  preparePurchase (jsonData) {
    const params = new HttpParams()
      .set('jsonData', jsonData);

    return firstValueFrom(super.requestAsObservable('', 'POST', 'purchase/prepare', params));
  }

  purchase (jsonData) {
    const params = new HttpParams()
      .set('jsonData', jsonData);

    return firstValueFrom(super.requestAsObservable('', 'POST', 'purchase/put', params));
  }

  preparePayment (paymentMethodId, invoiceId, companyId, token) {
    const params = new HttpParams()
      .set('paymentMethodId', paymentMethodId)
      .set('companyId', companyId)
      .set('token', token);

    return firstValueFrom(super.requestAsObservable('', 'POST', `payment/prepare/${invoiceId}`, params));
  }

  collectPayment (paymentIntentId, invoiceId, companyId, token) {
    const params = new HttpParams()
      .set('paymentIntentId', paymentIntentId)
      .set('companyId', companyId)
      .set('token', token);

    return firstValueFrom(super.requestAsObservable('', 'POST', `payment/collect/${invoiceId}`, params));
  }

  addTaxExemption (companyId, taxExemption, blobKey) {
    // var expiryDateString = $filter('date')(taxExemption.expiryDate, 'yyyy-MM-dd');

    return this.coreAPILoader()
      .then(function (coreAPI) {
        var obj = {
          'companyId': companyId,
          // 'country': taxExemption.country,
          // 'state': taxExemption.province,
          'blobKey': blobKey,
          'number': taxExemption.number,
          // 'expiryDate': expiryDateString
        };

        return coreAPI.taxExemption.add(obj);
      })
      .then(resp => {
        if (resp.error) {
          console.error('Error adding tax exemption: ', resp.message);
          return Promise.reject(resp.error);
        } else {
          return resp;
        }
      });
  }

  uploadTaxExemptionCertificate (file) {
    var formData = new FormData();

    formData.append('file', file);

    return this.coreAPILoader()
      .then(function (coreAPI) {
        return coreAPI.taxExemption.getUploadUrl();
      })
      .then(resp => {
        if (resp.error) {
          console.error('Error getting upload url: ', resp.message);
          return Promise.reject(resp.error);
        } else {
          return firstValueFrom(
            this.httpClient.post(resp.result.item, formData, {
              withCredentials: true,
              responseType: 'text'
            }))
            .then((response: any) => {
              return response;
            })
            .catch(error => {
              console.error('Error uploading file: ', error);
              return Promise.reject(error);
            });
        }
      });
  }

}

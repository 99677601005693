<div class="form-group mb-0">
  <div class="flex-row">
    <div class="row-entry">
      <label class="control-label distribution-label pull-left mb-0 u_margin-right">Distribution:</label>

      <mat-checkbox [shown]="!readonly" class="mr-1" id="allDisplaysCheckbox" (change)="outputDistributeToAll()" [(ngModel)]="distributeToAll" aria-labelledby="distributeToAllLabel"></mat-checkbox>
      @if (!readonly || distributeToAll) {
        <span id="distributeToAllLabel" class="mr-3 u_clickable" (click)="distributeToAll = !distributeToAll; outputDistributeToAll()" (keyup.enter)="distributeToAll = !distributeToAll; outputDistributeToAll()">Show on all displays{{ enableSubcompanyDistributeToAll ? ' in this company' : ''}}.</span>
      } @else if (readonly && !distributeToAll) {
        <span id="distributeToAllLabel">{{ distributionSelectionMessage }}</span>
      }
    </div>
  </div>
  <span id="distributionFieldMadero" class="madero-link u_clickable" timeline="timeline" (click)="manage()" [shown]="!readonly && !distributeToAll">

    <span id="distributionFieldTextMadero" tabindex="0" (keyup.enter)="manage()">{{ distributionSelectionMessage }}</span>
  </span>
  @if (subcompanyDistributeToAllAvailable) {
    <div class="flex-row">
      <div class="row-entry">

          <mat-checkbox [shown]="!readonly" class="mr-1" id="allSubcompanyDisplaysCheckbox" (change)="outputSubcompanyDistributeToAll()" [(ngModel)]="subcompanyDistributeToAll" aria-labelledby="subcompanyDistributeToAllLabel"></mat-checkbox>
          <span id="subcompanyDistributeToAllLabel" class="mr-3 u_clickable" (click)="subcompanyDistributeToAll = !subcompanyDistributeToAll; outputSubcompanyDistributeToAll()" (keyup.enter)="subcompanyDistributeToAll = !subcompanyDistributeToAll; outputSubcompanyDistributeToAll()">Show on all displays in sub companies.</span>
      </div>
    </div>
  }

</div>

<div class="calendar-day-month-header">
  <div class="mat-calendar-header">
    <span [id]="_periodButtonLabelId" class="cdk-visually-hidden" aria-live="polite">{{periodButtonDescription}}</span>
    <div class="mat-calendar-controls flex-row space-between">
      <button
        mat-icon-button
        type="button"
        class="mat-calendar-previous-button mdc-icon-button mat-mdc-icon-button mat-unthemed mat-mdc-button-base"
        [disabled]="!previousEnabled()"
        (click)="previousClicked()"
        [attr.aria-label]="prevButtonLabel"
      >
        <mat-icon svgIcon="chevron-left" />
      </button>
      <div class="mat-calendar-header-label">{{periodButtonText}}</div>
      <button
        mat-icon-button
        type="button"
        class="mat-calendar-next-button mdc-icon-button mat-mdc-icon-button mat-unthemed mat-mdc-button-base"
        [disabled]="!nextEnabled()"
        (click)="nextClicked()"
        [attr.aria-label]="nextButtonLabel"
      >
        <mat-icon svgIcon="chevron-right" />
      </button>
    </div>
  </div>

import { StatesModule } from '@uirouter/angular';

import { ViewportComponent } from './viewport/viewport.component';
import { ScreenSharingComponent } from './components/screen-sharing/screen-sharing.component';
import { ModeratorJoinComponent } from './components/moderator-join/moderator-join.component';
import { ModeratorRoomComponent } from './components/moderator-room/moderator-room.component';
import { ScreenSharingService } from './services/screen-sharing.service';

export const routes: StatesModule = {
  states: [

    {
      name: 'apps.screen-sharing',
      abstract: true,
      component: ViewportComponent,
      resolve: [
        {
          token: 'screenSharing',
          deps: [ScreenSharingService],
          resolveFn: (screenSharingService) => screenSharingService.clearErrors()
        }
      ]
    },
    {
      name: 'apps.screen-sharing.home',
      url: '/join?rvshare',
      component: ScreenSharingComponent,
      data: {
        rvshareAppEnabled: true,
        requiresAuth: false
      },
    },
    {
      name: 'apps.screen-sharing.moderator-join',
      url: '/screen-sharing/join?rvshare',
      component: ModeratorJoinComponent,
      data: {
        rvshareAppEnabled: true,
      }
    },
    {
      name: 'apps.screen-sharing.moderator-room',
      url: '/screen-sharing/room/:displayId/?rvshare',
      component: ModeratorRoomComponent,
      data: {
        rvshareAppEnabled: true,
      }
    }
  ]
};

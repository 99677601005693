<!-- Presentation Name -->
<stretchy-input class="presentation-name" [(ngModel)]="editorService.presentation.name" ngDefaultControl></stretchy-input>
<button id="deleteButton" [disabled]="!editorService.presentation.id" *requireRole="'cp ap'" (click)="confirmDelete()" class="btn-icon ml-2">
  <streamline-icon ariaLabel="Delete presentation" class="streamline-component-icon" name="trash" width="16" height="16"></streamline-icon>
</button>
<div class="btn-group" dropdown>
  <button id="moreSettingsButton" [matMenuTriggerFor]="moreMenu" class="btn btn-default-icon dropdown-toggle ml-2" [shown]="artboardService.designMode">
    <streamline-icon ariaLabel="Click for more options" name="ellipsis" width="20" height="20"></streamline-icon>
  </button>
  <mat-menu #moreMenu="matMenu">
    <button mat-menu-item id="changeTemplateButton" uiSref="apps.editor.add">New Presentation</button>
    <button mat-menu-item
          id="copyPresentationButton"
          [attr.disabled]="!editorService.presentation.id"
          (click)="editorService.copyPresentation()">Duplicate</button>
    <button mat-menu-item
          id="presentationPropertiesButton"
          [attr.disabled]="!editorService.presentation.id"
          (click)="editorService.openPresentationProperties()">Settings</button>
  </mat-menu>
</div>
<div class="flex-row hidden-xs ml-auto">
  <button class="btn-icon" aria-label="Zoom out" [disabled]="!artboardService.canZoomOut()" (click)="artboardService.zoomOut()">
    <i aria-label="Zoom out" class="fa fa-minus"></i>
  </button>
  <button type="button" id="zoomSelectorButton" [matMenuTriggerFor]="zoomMenu" class="form-control btn-select flex-row">
    <div class="row-entry">
      <span class="text-gray mr-auto">{{artboardService.zoomLevel*100 | number:'1.0-0' }}%</span>
      <span class="text-gray pl-2"><i aria-hidden="true" class="fa fa-sort"></i></span>
    </div>
  </button>
  <mat-menu #zoomMenu="matMenu">
    <button mat-menu-item (click)="artboardService.zoomFit()">Fit To Screen</button>
    <button mat-menu-item (click)="artboardService.zoomPercent(100)">Full Size</button>
    <mat-divider></mat-divider>
    <button mat-menu-item (click)="artboardService.zoomPercent(20)">20%</button>
    <button mat-menu-item (click)="artboardService.zoomPercent(50)">50%</button>
    <button mat-menu-item (click)="artboardService.zoomPercent(80)">80%</button>
    <button mat-menu-item (click)="artboardService.zoomPercent(100)">100%</button>
    <button mat-menu-item (click)="artboardService.zoomPercent(130)">130%</button>
    <button mat-menu-item (click)="artboardService.zoomPercent(150)">150%</button>
    <button mat-menu-item (click)="artboardService.zoomPercent(200)">200%</button>
  </mat-menu>
  <button class="btn-icon mr-5" aria-label="Zoom In" [disabled]="!artboardService.canZoomIn()" (click)="artboardService.zoomIn()">
    <i aria-label="Zoom In" class="fa fa-plus"></i>
  </button>
</div>

@if (editorService.hasUnsavedChanges && editorService.hasContentEditorRole()) {
  <button id="saveAndPreviewButton" class="btn btn-default btn-toolbar u_margin-left preview-button"  (click)="editorService.saveAndPreview()" [disabled]="editorService.savingPresentation">
    Save & Preview
  </button>
}
@else {
  <button id="previewButton" class="btn btn-default btn-toolbar preview-button" (click)="editorService.preview(editorService.presentation.id)" [disabled]="!editorService.presentation.id || editorService.savingPresentation">
    Preview
  </button>
}
